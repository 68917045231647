<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Device Model</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger text-center">
                Are you sure you wish to delete the device model?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-danger w-100" @click="delete_device_model()">
                Confirm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

export default {
  name: "ModelDelete",
  props: ["id"],
  data() {
    return {
      ref: this.$route.params.model,
    };
  },
  methods: {
    delete_device_model() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .delete(
          process.env.MIX_APP_URL +
            "/api/v1/models/" + this.ref,
          {
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
              "UMS-Authorization": umsTokens,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.emitter.emit("device_model_deleted");

            let deviceCheckinModal = bootstrap.Modal.getInstance(
              document.getElementById(this.id)
            );
            deviceCheckinModal.hide();

            this.emitter.emit("popup_alert", {
              type: "success",
              text: "Device Model was succesfuly deleted!",
            });

            setTimeout(() => {
              this.$router.push('/models');
            }, 2500);
          } else {
            this.emitter.emit("popup_alert", {
              type: "danger",
              text: response.message,
            });
          }
        });
    },
  },
  watch: {
    "model_ref": function (currentValue, oldValue) {
      this.ref = currentValue
    },
  },
};
</script>