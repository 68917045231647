import { createRouter, createWebHistory } from "vue-router";

// Import all page components
import Dashboard from "./pages/Dashboard.vue";
import DevicesPage from "./pages/Devices.vue";
import DevicePage from "./pages/Device.vue";
import FactoriesPage from "./pages/Factories.vue";
import FactoryPage from "./pages/Factory.vue";
import FactoryOverview from "./pages/fragments/FactoryOverview.vue";
import FactoryStats from "./pages/fragments/FactoryStats.vue";
import FactoryAnalytics from "./pages/fragments/FactoryAnalytics.vue";
import FactoryLogs from "./pages/fragments/FactoryLogs.vue";

import ModelsPage from "./pages/Models.vue";
import ModelPage from "./pages/Model.vue";
import ModelOverview from "./pages/fragments/ModelOverview.vue";
import ModelStats from "./pages/fragments/ModelStats.vue";
import ModelAnalytics from "./pages/fragments/ModelAnalytics.vue";
import ModelLogs from "./pages/fragments/ModelLogs.vue";

import LogsPage from "./pages/Logs.vue";
import LogPage from "./pages/Log.vue";
import MyAccountPage from "./pages/MyAccount.vue";
import MyAccountOverview from "./pages/fragments/MyAccountOverview.vue";

import NotFound from "./pages/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard - Inventory"
    }
  },
  {
    path: "/devices",
    name: "DevicesPage",
    component: DevicesPage,
    meta: {
      title: "Devices - Inventory"
    }
  },
  {
    path: "/devices/:device",
    name: "DevicePage",
    component: DevicePage,
    meta: {
      title: "Device - Inventory"
    }
  },
  {
    path: "/factories",
    name: "FactoriesPage",
    component: FactoriesPage,
    meta: {
      title: "Factories - Inventory"
    }
  },
  {
    path: "/factories/:factory",
    name: "FactoryPage",
    component: FactoryPage,
    meta: {
      title: "Factory - Inventory"
    }, children: [
      {
        path: "",
        name: "FactoryOverview",
        component: FactoryOverview,
        meta: {
          title: "Overview - REMOTE TECH LTD",
          submenu: "overview"
        }
      },
      {
        path: "stats",
        name: "FactoryStats",
        component: FactoryStats,
        meta: {
          title: "Stats - REMOTE TECH LTD",
          submenu: "stats"
        }
      },
      {
        path: "analytics",
        name: "FactoryAnalytics",
        component: FactoryAnalytics,
        meta: {
          title: "Analytics - REMOTE TECH LTD",
          submenu: "analytics"
        }
      },
      {
        path: "logs",
        name: "FactoryLogs",
        component: FactoryLogs,
        meta: {
          title: "Logs - REMOTE TECH LTD",
          submenu: "logs"
        }
      }
    ]
  },
  {
    path: "/models",
    name: "ModelsPage",
    component: ModelsPage,
    meta: {
      title: "Models - Inventory"
    }
  },
  {
    path: "/models/:model",
    name: "ModelPage",
    component: ModelPage,
    meta: {
      title: "Model - Inventory"
    }, children: [
      {
        path: "",
        name: "ModelOverview",
        component: ModelOverview,
        meta: {
          title: "Overview - REMOTE TECH LTD",
          submenu: "overview"
        }
      },
      {
        path: "stats",
        name: "ModelStats",
        component: ModelStats,
        meta: {
          title: "Stats - REMOTE TECH LTD",
          submenu: "stats"
        }
      },
      {
        path: "analytics",
        name: "ModelAnalytics",
        component: ModelAnalytics,
        meta: {
          title: "Analytics - REMOTE TECH LTD",
          submenu: "analytics"
        }
      },
      {
        path: "logs",
        name: "ModelLogs",
        component: ModelLogs,
        meta: {
          title: "Logs - REMOTE TECH LTD",
          submenu: "logs"
        }
      }
    ]
  },
  {
    path: "/logs",
    name: "LogsPage",
    component: LogsPage,
    meta: {
      title: "Logs - Inventory"
    }
  },
  {
    path: "/logs/:log",
    name: "LogPage",
    component: LogPage,
    meta: {
      title: "Log - Inventory"
    }
  },
  {
    path: "/account",
    name: "MyAccountPage",
    component: MyAccountPage,
    meta: {
      title: "My Account - Inventory"
    }, children: [
      {
        path: "",
        name: "MyAccountOverview",
        component: MyAccountOverview,
        meta: {
          title: "Overview - REMOTE TECH LTD",
          submenu: "overview"
        }
      },
    ]
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "Not Found - REMOTE TECH LTD",
    },
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "REMOTE TECH LTD";

  next();
})

export default router;