<template>
  <Breadcrumbs
    :breaditems="[
      { title: 'Models', link: '/models' },
      { title: model.name, link: '/models/' + model.ref },
    ]"
  />
  <div class="container-xl">
    <div class="row g-2">
      <div class="col-md-3 mt-2">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <b class="text-dark font-monospace">{{ model.name }}</b>
                <p class="small text-muted mb-0">{{ model.ref }}</p>
              </div>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <router-link
              :to="'/models/' + model.ref"
              :class="
                ('overview' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('overview')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-app-indicator"></i>
                </div>
                <div class="flex-grow-1 ms-3">Overview</div>
              </div>
            </router-link>
            <router-link
              :to="'/models/' + model.ref + '/stats'"
              :class="
                ('stats' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('stats')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-grid-3x3"></i>
                </div>
                <div class="flex-grow-1 ms-3">Stats</div>
              </div>
            </router-link>
            <router-link
              :to="'/models/' + model.ref + '/analytics'"
              :class="
                ('analytics' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('analytics')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-bar-chart-line-fill"></i>
                </div>
                <div class="flex-grow-1 ms-3">Analytics</div>
              </div>
            </router-link>
            <router-link
              :to="'/models/' + model.ref + '/logs'"
              :class="
                ('logs' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('logs')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-bug"></i>
                </div>
                <div class="flex-grow-1 ms-3">Logs</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-9 mt-2">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";

export default {
  name: "Model",
  components: { Breadcrumbs },
  data() {
    return {
      active_menu: "overview",
      model: {
        ref: this.$route.params.model,
        name: "",
      },
    };
  },
  created() {
    this.active_menu = this.$route.meta.submenu;
  },
  mounted() {
    this.emitter.on("model_details_loaded", (data) => {
      this.model.name = data.name ? data.name : "";
    });
  },
  methods: {
    setActiveMenu(menu) {
      this.active_menu = menu;
    },
  },
};
</script>