<template>
  <div class="card mb-2">
    <div class="card-header bg-white">
      <i class="bi bi-app-indicator me-2"></i>
      Overview
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-tools me-3"></i>Name:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="align-self-center small font-monospace">{{
              model.name
            }}</span>
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#modelName"
            >
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-hash me-3"></i>Reference:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="align-self-center small font-monospace">{{
              model.ref
            }}</span>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
              <i v-if="'sensor' == model.type" class="bi bi-thermometer-half me-3"></i>
              <i v-if="'module' == model.type" class="bi bi-cpu me-3"></i>
              <i v-if="'gateway' == model.type" class="bi bi-wifi me-3"></i>
              Type:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="align-self-center">
              {{model.type}}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="card mt-2">
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <i class="bi bi-exclamation-triangle-fill fs-4 text-danger"></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <b class="text-dark">Delete this Model</b>
            <p class="small text-muted mb-0">
              This action is irreversible so please use with caution.
            </p>
          </div>
          <button
            type="button"
            class="btn btn-outline-danger"
            data-bs-toggle="modal"
            data-bs-target="#modelDelete"
          >
            Delete Model
          </button>
        </div>
      </li>
    </ul>
  </div>

  <ModalModelName id="modelName" />
  <ModalModelDelete id="modelDelete" />
</template>

<script>
import axios from "axios";

import Utils from "../../helpers/utils";
import Storage from "../../helpers/storage";

import ModalModelName from "../../components/modals/ModelName.vue";
import ModalModelDelete from "../../components/modals/ModelDelete.vue";

export default {
  name: "ModelOverview",
  components: { ModalModelName, ModalModelDelete },
  data() {
    return {
      model_ref: this.$route.params.model,
      model: {},
    };
  },
  mounted() {
    this.emitter.on("model_details_updated", (data) => {
      this.fetch_model();
    });

    this.fetch_model();
  },
  methods: {
    fetch_model() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .get(
          process.env.MIX_APP_URL + "/api/v1/models/" + this.model_ref,
          {
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
              "UMS-Authorization": umsTokens,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.emitter.emit("model_details_loaded", response.data);

            this.model = response.data;
          } else {
            process.appdata.umslogin();
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 404) {
            console.log('Error', error.message);
            router.push({name: 'NotFound' })
          }
        });
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
  },
};
</script>
