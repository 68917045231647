<template>
  <Breadcrumbs :breaditems="[{ title: 'Models' }]" />
  <div class="container-xl">
    <div class="d-flex bd-highlight mb-2">
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1"><i class="bi bi-map"></i></span>
          <select v-model="filter.type" class="form-select bg-white border-start-0 ps-0" style="max-width: 225px">
            <option disabled="">Device Model</option>
            <option value="">All</option>
            <option value="gateway">Gateways</option>
            <option value="module">Modules</option>
            <option value="sensor">Sensors</option>
          </select>
        </div>
      </div>

      <div class="d-flex ms-auto">
        <div class="input-group me-2">
          <span class="input-group-text bg-white border-end-0"><i class="bi bi-search"></i></span>
          <input type="text" class="form-control border-start-0" v-model="filter.name" v-on:keyup.enter="fetch_models()"
            placeholder="Search..." />
        </div>
        <button type="button" class="
            btn
            bg-white
            border
            float-end
            text-decoration-none text-nowrap
            bd-highlight
          " data-bs-toggle="modal" data-bs-target="#newDeviceModel">
          New Model
        </button>
      </div>
    </div>

    <div class="card">
      <div class="table-responsive">
        <table class="table mb-0" id="tblDevices">
          <thead class="bg-light border-bottom">
            <th class="border-end text-nowrap px-3 py-2 fw-normal">
              <span>Model Name</span>
              <i class="bi bi-hash float-end"></i>
            </th>
            <th class="border-end text-nowrap px-3 py-2 fw-normal">
              <span>Devices</span>
              <i class="bi bi-cpu float-end"></i>
            </th>
            <th class="text-nowrap px-3 py-2 fw-normal">
              <span>Last Seen</span>
              <i class="bi bi-calendar-day float-end"></i>
            </th>
          </thead>
          <tbody class="border-top-0">
            <router-link v-for="model in models" v-bind:key="model.ref" :to="'/models/' + model.ref"
              class="list-group-item list-group-item-action" style="display:table-row;">
              <td class="px-0 py-2">
                <div class="d-flex align-items-center border-end px-3">
                  <div class="flex-shrink-0">
                    <i v-if="'sensor' == model.type" class="bi bi-thermometer-half fs-4"></i>
                    <i v-if="'module' == model.type" class="bi bi-cpu fs-4"></i>
                    <i v-if="'gateway' == model.type" class="bi bi-wifi fs-4"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <b class="text-dark font-monospace small">{{ model.name }}</b>
                    <p class="small text-muted mb-0">{{ model.ref }}</p>
                  </div>
                </div>
              </td>
              <td class="px-0 py-2">
                <div class="row mx-0 border-end">
                  <div class="col-6">
                    <b class="d-block"><i class="bi bi-calendar-month"></i></b>
                    <small class="d-block text-muted">Total</small>
                  </div>
                  <div class="col-6 text-end">
                    <b class="d-block">{{
                        model.device_count_last_month
                    }}</b>
                    <small class="d-block text-muted">{{
                        model.device_count_total
                    }}</small>
                  </div>
                </div>
              </td>
              <td class="px-0 py-2">
                <div v-if="model.latest_device" class="text-end pe-3">
                  <b class="d-block">{{ timeAgo(model.latest_device.created_at) }}</b>
                  <small class="d-block text-muted">{{
                      localMachineTime(model.latest_device.created_at)
                  }}</small>
                </div>
                <div v-else class="text-end pe-3">
                  <b class="d-block">never</b>
                  <small class="d-block text-muted">N/A</small>
                </div>
              </td>
            </router-link>
          </tbody>
        </table>
      </div>
      <pagination v-if="paginator.current_page" :records="paginator.total_items" :per-page="10" v-model="current_page"
        :options="paginatorOptions"></pagination>
    </div>
  </div>

  <ModalNewDeviceModel id="newDeviceModel" />
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";

import Utils from "../helpers/utils";
import Storage from "../helpers/storage";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import RTPagination from "../components/RTPagination.vue";

import ModalNewDeviceModel from "../components/modals/NewDeviceModel.vue";

export default {
  name: "Factories",
  components: { Breadcrumbs, Pagination, RTPagination, ModalNewDeviceModel },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      models: [],
      filter: {
        type: "",
        name: "",
      },
      current_page: 1,
    };
  },
  mounted() {
    this.emitter.on("device_model_created", (data) => {
      this.fetch_models();
    });

    this.fetch_models();
  },
  methods: {
    fetch_models() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      let url =
        process.env.MIX_APP_URL + "/api/v1/models?paginated=true&page=" + this.current_page;

      if ("" !== this.filter.region) {
        url += "&type=" + encodeURIComponent(this.filter.type);
      }
      if ("" !== this.filter.name) {
        url += "&search=" + encodeURIComponent(this.filter.name);
      }

      axios
        .get(url, {
          headers: {
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          this.models = response.data.data;
          this.paginator = response.data.meta;
          this.device_totals = response.data.device_totals;
        })
        .catch((error) => {
          process.appdata.refreshTokens();
        });
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
  },
  watch: {
    current_page: function () {
      this.fetch_models();
    },
    "filter.type": function () {
      this.fetch_models();
    },
  },
};
</script>