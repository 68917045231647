<template>
  <div class="card">
    <div class="card-header bg-white">
      <i class="bi bi-grid-3x3 me-2"></i>
      Stats
    </div>

    <div class="table-responsive">
      <table class="table mb-0" id="tblDevices">
        <thead class="bg-light border-bottom">
          <th class="border-end text-nowrap px-3 py-2 fw-normal">
            <span>Factory</span>
          </th>
          <th class="border-end text-nowrap text-center px-3 py-2 fw-normal">
            <span>Last 30 days</span>
          </th>
          <th class="border-end text-nowrap text-center px-3 py-2 fw-normal">
            <span>This Quarter (Q4)</span>
          </th>
          <th class="border-end text-nowrap text-center px-3 py-2 fw-normal">
            <span>This Year (2022)</span>
          </th>
          <th class="text-nowrap px-3 py-2 fw-normal">
            <span>Total</span>
          </th>
        </thead>
        <tbody class="border-top-0">
          <tr v-for="stat in stats" v-bind:key="stat.id">
            <td class="px-0">
              <div class="d-flex align-items-center px-3 border-end">
                <div class="flex-grow-1">
                  <b class="text-dark font-monospace small">{{ stat.name }}</b>
                  <p class="small text-muted mb-0">{{ stat.ref }}</p>
                </div>
                <div class="flex-shrink-0">
                  <router-link :to="'/models/' + model.ref + '/analytics'"><i class="bi bi bi-graph-up fs-5"
                      data-bs-toggle="tooltip" data-bs-placement="top" title=""
                      data-bs-original-title="View Analytics"></i></router-link>
                </div>
              </div>
            </td>

            <td class="px-0 text-nowrap">
              <div class="row mx-0 border-end">
                <div class="col-6">
                  <b class="d-block"><i class="bi bi-hash"></i></b>
                  <small class="d-block text-muted">Change</small>
                </div>
                <div class="col-6 text-end">
                  <span class="d-block fw-bold">{{ stat.this_month }}</span>
                  <span class="d-block small" :class="{
                    'text-muted': (stat.last_month == stat.this_month),
                    'text-success': (stat.last_month < stat.this_month),
                    'text-danger': (stat.last_month > stat.this_month)
                  }">
                    {{ percentage_diff(stat.last_month, stat.this_month) }}
                  </span>
                </div>
              </div>
            </td>

            <td class="px-0 text-nowrap">
              <div class="row mx-0 border-end">
                <div class="col-6">
                  <b class="d-block"><i class="bi bi-hash"></i></b>
                  <small class="d-block text-muted">Change</small>
                </div>
                <div class="col-6 text-end">
                  <span class="d-block fw-bold">{{ stat.this_quarter }}</span>
                  <span class="d-block small" :class="{
                    'text-muted': (stat.last_quarter == stat.this_quarter),
                    'text-success': (stat.last_quarter < stat.this_quarter),
                    'text-danger': (stat.last_quarter > stat.this_quarter)
                  }">
                    {{ percentage_diff(stat.last_quarter, stat.this_quarter) }}
                  </span>
                </div>
              </div>
            </td>

            <td class="px-0 text-nowrap">
              <div class="row mx-0 border-end">
                <div class="col-6">
                  <b class="d-block"><i class="bi bi-hash"></i></b>
                  <small class="d-block text-muted">Change</small>
                </div>
                <div class="col-6 text-end">
                  <span class="d-block fw-bold">{{ stat.this_year }}</span>
                  <span class="d-block small" :class="{
                    'text-muted': (stat.last_year == stat.this_year),
                    'text-success': (stat.last_year < stat.this_year),
                    'text-danger': (stat.last_year > stat.this_year)
                  }">
                    {{ percentage_diff(stat.last_year, stat.this_year) }}
                  </span>
                </div>
              </div>
            </td>

            <td class="px-0 text-nowrap">
              <div class="row mx-0">
                <div class="col-6">
                  <b class="d-block"><i class="bi bi-hash"></i></b>
                  <small class="d-block text-muted">Change</small>
                </div>
                <div class="col-6 text-end">
                  <span class="d-block fw-bold">{{ stat.total }}</span>
                  <span class="d-block small text-muted">N/A</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

export default {
  name: "ModelStats",
  components: {},
  data() {
    return {
      model: {
        ref: this.$route.params.model,
      },
      stats: [],
    };
  },
  mounted() {
    this.fetch_stats();
  },
  methods: {
    fetch_stats() {
      var sessionTokens = Storage.get("session_tokens", true);

      axios
        .get(
          process.env.MIX_APP_URL +
          "/api/v1/models/" +
          this.model.ref +
          "/stats",
          {
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.stats = response.data;
          } else {
            process.appdata.umslogin();
          }
        });
    },
    percentage_diff(old_value, new_value) {
      if (old_value == 0) {
        old_value++
        new_value++
      }
      let change = ((new_value - old_value) / old_value) * 100;
      return change > 0 ? '+' + Math.round(change) + '%' : Math.round(change) + '%'
    }
  },
};
</script>
