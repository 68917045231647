<template>
  <Breadcrumbs :breaditems="[{ title: 'Factories' }]" />
  <div class="container-xl">
    <div class="d-flex bd-highlight mb-2">
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1"><i class="bi bi-map"></i></span>
          <select v-model="filter.region" class="form-select bg-white border-start-0 ps-0" style="max-width: 225px">
            <option value="--" disabled>Region</option>
            <option value="">All</option>
            <option v-for="region in regions" v-bind:key="region">
              {{ region }}
            </option>
          </select>
        </div>
      </div>

      <div class="d-flex ms-auto">
        <div class="input-group me-2">
          <span class="input-group-text bg-white border-end-0"><i class="bi bi-search"></i></span>
          <input type="text" class="form-control border-start-0" v-model="filter.name"
            v-on:keyup.enter="fetch_factories()" placeholder="Search..." />
        </div>
        <button type="button" class="
            btn
            bg-white
            border
            float-end
            text-decoration-none text-nowrap
            bd-highlight
          " data-bs-toggle="modal" data-bs-target="#newFactory">
          New Factory
        </button>
      </div>
    </div>

    <div class="card">
      <div class="table-responsive">
        <table class="table mb-0" id="tblDevices">
          <thead class="bg-light border-bottom">
            <th class="border-end text-nowrap px-3 py-2 fw-normal">
              <span>Factory Name</span>
              <i class="bi bi-hash float-end"></i>
            </th>
            <th class="border-end text-nowrap px-3 py-2 fw-normal">
              <span>Gateways</span>
              <i class="bi bi-wifi float-end"></i>
            </th>
            <th class="border-end text-nowrap px-3 py-2 fw-normal">
              <span>Modules</span>
              <i class="bi bi-cpu float-end"></i>
            </th>
            <th class="border-end text-nowrap px-3 py-2 fw-normal">
              <span>Sensors</span>
              <i class="bi bi-thermometer-half float-end"></i>
            </th>
            <th class="text-nowrap px-3 py-2 fw-normal">
              <span>Last Seen</span>
              <i class="bi bi-calendar-day float-end"></i>
            </th>
          </thead>
          <tbody class="border-top-0">
            <router-link v-for="factory in factories" v-bind:key="factory.ref" :to="'/factories/' + factory.ref"
              class="list-group-item list-group-item-action" style="display:table-row;">
              <td class="px-0 py-2">
                <div class="px-3 border-end">
                  <b class="text-dark font-monospace small">{{ factory.name }}</b>
                  <p class="small text-muted mb-0">{{ factory.ref }} - {{ factory.region }}</p>
                </div>
              </td>

              <td class="px-0 py-2">
                <div class="row mx-0 border-end">
                  <div class="col-6">
                    <b class="d-block"><i class="bi bi-calendar-month"></i></b>
                    <small class="d-block text-muted">Total</small>
                  </div>
                  <div class="col-6 text-end">
                    <b class="d-block">{{
                        factory.device_count_last_month.gateways
                    }}</b>
                    <small class="d-block text-muted">{{
                        factory.device_count_total.gateways
                    }}</small>
                  </div>
                </div>
              </td>
              <td class="px-0 py-2">
                <div class="row mx-0 border-end">
                  <div class="col-6">
                    <b class="d-block"><i class="bi bi-calendar-month"></i></b>
                    <small class="d-block">Total</small>
                  </div>
                  <div class="col-6 text-end">
                    <b class="d-block">{{ factory.device_count_total.modules }}</b>
                    <small class="d-block text-muted">{{
                        factory.device_count_last_month.modules
                    }}</small>
                  </div>
                </div>
              </td>
              <td class="px-0 py-2">
                <div class="row mx-0 border-end">
                  <div class="col-6">
                    <b class="d-block"><i class="bi bi-calendar-month"></i></b>
                    <small class="d-block">Total</small>
                  </div>
                  <div class="col-6 text-end">
                    <b class="d-block">{{ factory.device_count_total.sensors }}</b>
                    <small class="d-block text-muted">{{
                        factory.device_count_total.sensors
                    }}</small>
                  </div>
                </div>
              </td>
              <td class="px-0 py-2">
                <div class="text-end pe-3">
                  <b class="d-block">{{ timeAgo(factory.created_at) }}</b>
                  <small class="d-block text-muted">{{
                      localMachineTime(factory.created_at)
                  }}</small>
                </div>
              </td>
            </router-link>
          </tbody>
        </table>
      </div>
      <pagination v-if="paginator.current_page" :records="paginator.total_items" :per-page="10" v-model="current_page"
        :options="paginatorOptions"></pagination>
    </div>
  </div>

  <ModalNewFactory id="newFactory" />
  <ModalFactoryAPIToken id="factoryAPIToken" :api_token="api_token" />
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";

import Utils from "../helpers/utils";
import Storage from "../helpers/storage";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import RTPagination from "../components/RTPagination.vue";

import ModalNewFactory from "../components/modals/NewFactory.vue";
import ModalFactoryAPIToken from "../components/modals/FactoryAPIToken.vue";

export default {
  name: "Factories",
  components: { Breadcrumbs, Pagination, RTPagination, ModalNewFactory, ModalFactoryAPIToken },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      factories: [],
      regions: [],
      filter: {
        region: "",
        name: "",
      },
      current_page: 1,
      api_token: null
    };
  },
  mounted() {
    this.emitter.on("factory_created", (data) => {
      this.fetch_factories();
      this.api_token = data.api_token;
      $('#factoryAPIToken').modal('show');
    });

    this.fetch_factories();
    this.fetch_regions();
  },
  methods: {
    fetch_regions() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .get(process.env.MIX_APP_URL + "/api/v1/factories/regions", {
          headers: {
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          this.regions = response.data;
        })
        .catch((error) => {
          process.appdata.refreshTokens();
        });
    },
    fetch_factories() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      let url =
        process.env.MIX_APP_URL + "/api/v1/factories?paginated=true&page=" + this.current_page;

      if ("" !== this.filter.region) {
        url += "&region=" + encodeURIComponent(this.filter.region);
      }
      if ("" !== this.filter.name) {
        url += "&search=" + encodeURIComponent(this.filter.name);
      }

      axios
        .get(url, {
          headers: {
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          this.factories = response.data.data;
          this.paginator = response.data.meta;
          this.device_totals = response.data.device_totals;
        })
        .catch((error) => {
          process.appdata.refreshTokens();
        });
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
  },
  watch: {
    current_page: function () {
      this.fetch_factories();
    },
    "filter.region": function () {
      this.fetch_factories();
    },
  },
};
</script>