<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white border-bottom">
    <div class="container-xl">
      <a class="navbar-brand fw-bold text-primary" href="/"
        ><i class="bi bi-clipboard-check"></i> Inventory</a
      >
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarsExample09"
        aria-controls="navbarsExample09"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarsExample09">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="'' === currentPath ? 'active' : ''"
              to="/"
              @click="setCurrentPath('')"
              >Dashboard</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="'devices' === currentPath ? 'active' : ''"
              to="/devices"
              @click="setCurrentPath('devices')"
              >Devices</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="'factories' === currentPath ? 'active' : ''"
              to="/factories"
              @click="setCurrentPath('factories')"
              >Factories</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="'models' === currentPath ? 'active' : ''"
              to="/models"
              @click="setCurrentPath('models')"
              >Models</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="'logs' === currentPath ? 'active' : ''"
              to="/logs"
              @click="setCurrentPath('logs')"
              >Logs</router-link
            >
          </li>
        </ul>
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" href="#" @click.stop.prevent="scan_qrcode()">
              <i class="bi bi-qr-code-scan"></i>
            </a>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="'account' === currentPath ? 'active' : ''"
              to="/account"
              @click="setCurrentPath('account')"
              >{{
                currentUser.first_name + " " + currentUser.last_name
              }}</router-link
            >
          </li>
          <li class="nav-item">
            <span
              class="nav-link"
              aria-current="page"
              @click="logout"
              style="cursor: pointer"
              ><i class="bi bi-power"></i
            ></span>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <ModalQrCodeScanner />
</template>

<script>
import ModalQrCodeScanner from "./modals/QrCodeScanner.vue";

export default {
  name: "Navbar",
  components: { ModalQrCodeScanner },
  data() {
    return {
      expanded: false,
      currentUser: process.appdata.user,
      currentPath: window.location.pathname.split("/")[1],
    };
  },
  mounted() {
    this.emitter.on("qrcode_result", (device) => {
      this.$router.push('/devices/' + device);
    });
  },
  methods: {
    setCurrentPath(path) {
      this.currentPath = path;
    },
    collapse() {
      this.expanded = false;
    },
    logout() {
      process.appdata.logout();
    },
    scan_qrcode() {
      this.emitter.emit("show_qrcode");
    },
  },
};
</script>
