<template>
  <Breadcrumbs :breaditems="[{ title: 'Devices' }]" />
  <div class="container-xl">
    <div class="d-flex mb-2" id="deviceFilters">
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1"><i class="bi bi-upc-scan"></i></span>
          <select v-model="filter.model" class="form-select bg-white border-start-0 ps-0">
            <option value="---" disabled="">Device Model</option>
            <option value="">All</option>
            <optgroup label="Gateways">
              <option v-for="model in gateway_models" v-bind:key="model.ref" :value="model.ref">
                {{ model.name }}
              </option>
            </optgroup>
            <optgroup label="Modules">
              <option v-for="model in module_models" v-bind:key="model.ref" :value="model.ref">
                {{ model.name }}
              </option>
            </optgroup>
            <optgroup label="Sensors">
              <option v-for="model in sensor_models" v-bind:key="model.ref" :value="model.ref">
                {{ model.name }}
              </option>
            </optgroup>
          </select>
        </div>
      </div>
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1"><i class="bi bi-circle"></i></span>
          <select v-model="filter.status" class="form-select bg-white border-start-0 ps-0">
            <option disabled="---">Status</option>
            <option value="">All</option>
            <option value="ready" selected="">Ready to Deploy</option>
            <option value="deployed">Deployed</option>
            <option value="undeployable">Undeployable</option>
            <option value="broken">Broken</option>
            <option value="lost">Lost</option>
          </select>
        </div>
      </div>
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1"><i class="bi bi-building"></i></span>
          <select v-model="filter.organisation" class="form-select bg-white border-start-0 ps-0">
            <option disabled="---">Organisation</option>
            <option value="">All</option>
            <option v-for="organisation in organisations" v-bind:key="organisation.id" :value="organisation.id">
              {{ organisation.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1"><i
              class="bi bi-person-badge"></i></span>
          <select v-model="filter.assignee" class="form-select bg-white border-start-0 ps-0">
            <option value="---" disabled="">Assignee</option>
            <option value="">All</option>
            <option v-for="assignee in assignees" v-bind:key="assignee.id" :value="assignee.id">
              {{ assignee.full_name }}
            </option>
          </select>
        </div>
      </div>
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1">
            <i class="bi bi-bookmark"></i>
          </span>
          <select v-model="filter.tag" class="form-select bg-white border-start-0 ps-0">
            <option disabled="">Tag</option>
            <option value="">All</option>
            <option v-for="tag in tags" v-bind:key="tag.id" :value="tag.name.en">
              {{ tag.name.en }}
            </option>
          </select>
        </div>
      </div>
      <div class="ms-auto">
        <div class="input-group">
          <span class="input-group-text bg-white border-end-0"><i class="bi bi-search"></i></span>
          <input v-model="filter.name" v-on:keyup.enter="fetch_devices()" type="text"
            class="form-control border-start-0" placeholder="Search..." />
          <div class="btn-group">
            <button type="button" class="btn btn-outline-secondary border" data-bs-toggle="modal"
              data-bs-target="#newDevice" style="border-radius:0;">
              <i class="bi bi-plus"></i> New
            </button>
            <button type="button" class="btn btn-outline-secondary border dropup" @click="show_bulk_actions">
              <i class="bi bi-cart-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="table-responsive">
        <table class="table mb-0" id="tblDevices">
          <thead class="bg-light border-bottom">
            <th class="border-end text-nowrap px-3 py-2 fw-normal">
              <span>Device</span>
              <i class="bi bi-arrow-down-up float-end"></i>
            </th>
            <th class="border-end text-nowrap px-3 py-2 fw-normal">
              <span>Organisation</span>
              <i class="bi bi-building float-end"></i>
            </th>
            <th class="border-end text-nowrap px-3 py-2 fw-normal">
              <span>Assignee</span>
              <i class="bi bi-person-badge float-end"></i>
            </th>
            <th class="text-nowrap px-3 py-2 fw-normal">
              <span>Tags / Last Updated</span>
              <i class="bi bi-arrow-down float-end text-primary"></i>
            </th>
          </thead>
          <tbody class="border-top-0">
            <router-link v-for="device in devices" v-bind:key="device.id" :to="'/devices/' + device.eui"
              class="list-group-item list-group-item-action" style="display:table-row;">
              <td class="px-0 py-2">
                <div class="d-flex align-items-center border-end px-2">
                  <div class="flex-shrink-0">
                    <i v-if="'sensor' == device.type" class="bi bi-thermometer-half fs-4"></i>
                    <i v-if="'module' == device.type" class="bi bi-cpu fs-4"></i>
                    <i v-if="'gateway' == device.type" class="bi bi-wifi fs-4"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <b class="text-dark font-monospace small">{{ device.eui }}</b>
                    <p class="small text-muted mb-0">{{ device.name }}</p>
                  </div>
                  <div class="flex-shrink-0">
                    <i v-if="'deployed' == device.status" class="bi bi-check-circle fs-5 text-success"
                      data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Deployed"></i>
                    <i v-if="'ready' == device.status" class="bi bi-plus-circle fs-5 text-success"
                      data-bs-toggle="tooltip" data-bs-placement="top" title=""
                      data-bs-original-title="Ready to deploy"></i>
                    <i v-if="'undeployable' == device.status" class="bi bi-exclamation-circle fs-5 text-warning"
                      data-bs-toggle="tooltip" data-bs-placement="top" title=""
                      data-bs-original-title="Undeployable"></i>
                    <i v-if="'broken' == device.status" class="bi bi-slash-circle fs-5 text-danger"
                      data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Broken"></i>
                    <i v-if="'lost' == device.status" class="bi bi-x-circle fs-5 text-danger" data-bs-toggle="tooltip"
                      data-bs-placement="top" title="" data-bs-original-title="Lost"></i>
                  </div>
                </div>
              </td>
              <td class="px-0 py-2">
                <div class="border-end px-2">
                  <b class="d-block">{{
                      device.organisation ? device.organisation.name : ""
                  }}</b>
                  <p class="small text-muted mb-0">
                    {{ device.organisation ? device.organisation.industry : "" }}
                  </p>
                </div>
              </td>
              <td class="px-0 py-2">
                <div class="d-flex align-items-center border-end px-2">
                  <div class="flex-shrink-0">
                    <img src="/images/icons/person-circle.svg" alt="twbs" class="rounded-circle align-self-center"
                      width="36" height="36" />
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <b class="text-dark">{{
                        device.assignee ? device.assignee.name : ""
                    }}</b>
                    <p class="small text-muted mb-0">
                      {{ device.assignee ? device.assignee.email : "" }}
                    </p>
                  </div>
                </div>
              </td>
              <td class="px-0 py-2">
                <div class="px-2">
                  <b class="d-block">
                    <span v-for="tag in device.tags" v-bind:key="tag.id" class="badge bg-light border text-dark me-1">
                      {{ tag.name.en }}
                    </span>
                  </b>
                  <p class="small text-muted mb-0">
                    {{ localMachineTime(device.updated_at) }}
                    <span class="float-end">{{
                        timeAgo(device.updated_at)
                    }}</span>
                  </p>
                </div>
              </td>
            </router-link>
          </tbody>
        </table>
      </div>
      <pagination v-if="paginator.current_page" :records="paginator.total_items" :per-page="10" v-model="current_page"
        :options="paginatorOptions"></pagination>
    </div>
  </div>

  <ModalNewDevice id="newDevice" :models="models" :factories="factories" />
  <ModalBulkActions />
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";

import Storage from "../helpers/storage";
import Utils from "../helpers/utils";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import RTPagination from "../components/RTPagination.vue";

import ModalNewDevice from "../components/modals/NewDevice.vue";
import ModalBulkActions from "../components/modals/BulkActions.vue";

export default {
  name: "Devices",
  components: { Breadcrumbs, Pagination, RTPagination, ModalNewDevice, ModalBulkActions },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      models: [],
      organisations: [],
      assignees: [],
      factories: [],
      tags: [],
      filter: {
        model: "",
        status: "",
        organisation: "",
        assignee: "",
        tag: "",
        name: "",
      },
      devices: [],
      current_page: 1,
    };
  },
  mounted() {
    this.emitter.on("device_created", (data) => {
      this.$router.push('/devices/' + data.eui);
    });

    this.emitter.on("bulk_actions_assigned", (data) => {
      this.fetch_devices();
    });

    this.fetch_models();
    this.fetch_organisations();
    this.fetch_assignees();
    this.fetch_factories();
    this.fetch_devices();
    this.fetch_tags();

    setTimeout(() => {
      jQuery("[data-bs-toggle='tooltip']").each(function () {
        jQuery(this).tooltip();
      });
    }, 1000);
  },
  computed: {
    gateway_models: function () {
      return this.models.filter(function (model) {
        return model.type == "gateway";
      });
    },
    module_models: function () {
      return this.models.filter(function (model) {
        return model.type == "module";
      });
    },
    sensor_models: function () {
      return this.models.filter(function (model) {
        return model.type == "sensor";
      });
    },
  },
  methods: {
    fetch_models() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .get(process.env.MIX_APP_URL + "/api/v1/models", {
          headers: {
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          this.models = response.data;
        })
        .catch((error) => {
          process.appdata.refreshTokens();
        });
    },
    fetch_factories() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .get(process.env.MIX_APP_URL + "/api/v1/factories", {
          headers: {
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          this.factories = response.data;
        })
    },
    fetch_organisations() {
      this.filter.assignee = "";

      axios
        .get(process.env.MIX_RT_UMS_URL + "organizations?items=1000", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.organisations = response.data.data.sort((one, two) => {
            return one.name > two.name;
          });
        });
    },
    fetch_assignees() {
      let url = process.env.MIX_RT_UMS_URL;

      if ("" !== this.filter.organisation) {
        url += "organizations/" + this.filter.organisation + "/users";
      } else {
        url += "admin/users?items=1000&filter[user.full_name]=";
      }

      axios
        .get(url, {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (response.data.data) {
            this.assignees = response.data.data.sort((one, two) => {
              return one.full_name > two.full_name;
            });
          } else {
            this.assignees = response.data[0].users
              .filter((item) => !!item)
              .map((item) => ({
                full_name: item.first_name + " " + item.last_name,
              }))
              .sort((one, two) => {
                return one.full_name > two.full_name;
              });
          }
        });
    },

    fetch_tags() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens"),
        url = process.env.MIX_APP_URL + "/api/v1/tags";

      axios
        .get(url, {
          headers: {
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          this.tags = response.data;
        })
        .catch((error) => {
          process.appdata.refreshTokens();
        });
    },

    fetch_devices() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens"),
        url =
          process.env.MIX_APP_URL + "/api/v1/devices?page=" + this.current_page;

      if ("" !== this.filter.model) {
        url += "&model=" + this.filter.model;
      }

      if ("" !== this.filter.status) {
        url += "&status=" + this.filter.status;
      }

      if ("" !== this.filter.organisation) {
        url += "&organisation=" + this.filter.organisation;
      }

      if ("" !== this.filter.assignee) {
        url += "&assignee=" + this.filter.assignee;
      }

      if ("" !== this.filter.tag) {
        url += "&tags[0]=" + this.filter.tag;
      }

      if ("" !== this.filter.name) {
        url += "&search=" + encodeURIComponent(this.filter.name);
      }

      axios
        .get(url, {
          headers: {
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          this.devices = response.data.data;
          this.paginator = response.data.meta;
        })
        .catch((error) => {
          process.appdata.refreshTokens();
        });
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    show_bulk_actions() {
      this.emitter.emit("show_bulk_actions");
    },
  },
  watch: {
    current_page: function () {
      this.fetch_devices();
    },
    "filter.model": function () {
      this.current_page = 1;
      this.fetch_devices();
    },
    "filter.status": function () {
      this.current_page = 1;
      this.fetch_devices();
    },
    "filter.assignee": function () {
      this.current_page = 1;
      this.fetch_devices();
    },
    "filter.organisation": function () {
      this.fetch_assignees();
      this.fetch_devices();
    },
    "filter.tag": function () {
      this.current_page = 1;
      this.fetch_devices();
    },
  },
};
</script>