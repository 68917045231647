<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Clear List</h5>
          <button
            type="button"
            class="btn-close"
            @click="clear_list(false)"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-secondary text-center">
                Are you sure you wish to clear the device list?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" @click="clear_list(false)">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-danger w-100" @click="clear_list(true)">
                Confirm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BulkActionsClearList",
  props: ["id"],
  methods: {
    clear_list(status) {
      this.emitter.emit("bulk_actions_clear_list", status);

      let bulkActionsClearListModal = bootstrap.Modal.getInstance(
        document.getElementById(this.id)
      );
      bulkActionsClearListModal.hide();
    }
  }
};
</script>