<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update Device Status</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row" v-if="nextStatus != null">
            <div class="col-12">
              <div class="alert alert-success text-center">
                Are you sure you wish to set the device status to {{capitalizeText(nextStatus)}}?
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12 mb-3">
              <label for="name" class="form-label">Device Status</label
              ><select class="form-control" v-model="status">
                <option value="deployed">Deployed</option>
                <option value="ready">Ready to Deploy</option>
                <option value="undeployable">Undeployable</option>
                <option value="broken">Broken</option>
                <option value="lost">Lost</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <button type="button" class="btn btn-primary w-100" @click="update_device">
                Update Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";
import Utils from "../../helpers/utils";

export default {
  name: "DeviceStatus",
  props: ["id", "nextStatus"],
  data() {
    return {
      eui: "",
      status: "deployed",
    };
  },
  mounted() {
    this.emitter.on("device_details_loaded", (data) => {
      this.eui = data.eui ? data.eui : "";
      this.status = data.status ? data.status : "";
    });
  },
  methods: {
    update_device() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      let status = this.nextStatus == null ? this.status : this.nextStatus;

      axios
        .post(
          process.env.MIX_APP_URL +
            "/api/v1/devices/" +
            this.eui +
            "/quality_check",
          {
            status: status,
          },
          {
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
              "UMS-Authorization": umsTokens,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              this.emitter.emit("device_status_updated");

              let factoryNameModal = bootstrap.Modal.getInstance(
                document.getElementById(this.id)
              );
              factoryNameModal.hide();

              this.emitter.emit("popup_alert", {
                type: "success",
                text: "Device status was succesfuly updated!",
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    capitalizeText(text) {
      return Utils.capitalizeText(text);
    },
  },
};
</script>