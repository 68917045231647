<template>
  <div class="modal fade" tabindex="-1" id="bulkActions">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Bulk Actions</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row mb-2">
            <div class="col-md">
              <div class="alert alert-secondary">
                Please allow the camera usage to be able to add devices to the bulk action list below.
              </div>

              <div class="row mb-3">
                <div class="col-md">
                    <ul class="list-group">
                      <li class="list-group-item d-flex p-0 border-0">
                        <div class="w-25 py-2 px-3 bg-light">
                          <i class="bi bi-phone-vibrate me-3"></i>
                          Devices:
                        </div>
                        <div class="w-75 py-2 px-3 border">
                          <div class="d-flex justify-content-around">
                            <span>
                              <i class="bi bi-wifi me-2"></i>
                              Gateways
                            </span>
                            <span>
                              <i class="bi bi-cpu me-2"></i>
                              Modules
                            </span>
                            <span>
                              <i class="bi bi-thermometer-half me-2"></i>
                              Sensors
                            </span>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item d-flex p-0 border-0">
                        <div class="w-25 py-2 px-3 bg-light">
                          <i class="bi bi-hash me-3"></i>
                          Count:
                        </div>
                        <div class="w-75 py-2 px-3 border">
                          <div class="d-flex justify-content-around">
                              <span>{{ gateways_count || '-' }}</span>
                              <span>{{ modules_count || '-' }}</span>
                              <span>{{ sensors_count || '-' }}</span>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item d-flex p-0 border-0">
                        <div class="w-25 py-2 px-3 bg-light">
                          <i class="bi bi-person-check me-3"></i>Total:
                        </div>
                        <div class="w-75 py-2 px-3 border">
                          <div class="d-flex justify-content-around">
                              <b>{{ devices.length }} device{{ devices.length == 1 ? '' : 's' }}</b>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
              </div>

              <div class="row align-self-end">
                <div class="col-md">
                  <button type="button" class="btn btn-outline-secondary w-100" data-bs-toggle="modal" data-bs-target="#clearList" :disabled="devices.length==0">
                    Clear list
                  </button>
                </div>
                <div class="col-md">
                  <button type="button" class="btn btn-outline-danger w-100" data-bs-toggle="modal" data-bs-target="#bulkActionCheckout" :disabled="devices.length==0">
                    Check Out
                  </button>
                </div>
                <div class="col-md">
                  <button type="button" class="btn btn-outline-warning w-100" data-bs-toggle="modal" data-bs-target="#bulkActionCheckin" :disabled="devices.length==0">
                    Check in
                  </button>
                </div>
                <div class="col-md">
                  <button type="button" class="btn btn-outline-primary w-100" data-bs-toggle="modal" data-bs-target="#bulkActionStatusUpdate" :disabled="devices.length==0">
                    Update Status
                  </button>
                </div>
              </div>

            </div>
            <div class="col-md-4">
              <qrcode-stream @init="onInit" @decode="onDecode" :camera="camera" style="height:264px;width:352px" >
                <div class="bg-light d-flex align-items-center justify-content-center d-block" style="height:264px;width:352px" v-if="status !== 'ready'">
                  <div class="text-success" v-if="status==='device_found'">
                    <i class="bi bi-check-circle"></i>
                    Device Found!!
                  </div>
                  <div class="text-warning" v-if="status==='device_duplicate'">
                    <i class="bi bi-exclamation-circle"></i>
                    Device Already Added!!
                  </div>
                  <div class="text-danger" v-if="status==='device_invalid'">
                    <i class="bi bi-x-circle"></i>
                    Invalid Device!!
                  </div>
                  <div class="spinner-border" role="status" v-if="status==='loading' || status==='loading_device'">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </qrcode-stream>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md">
              <div class="row">
                <div class="col-md">
                  <div class="input-group">
                    <span class="input-group-text bg-white border-end-1"><i class="bi bi-search"></i></span>
                    <input v-model="deviceSearch" @change="onDecode($event.target.value); deviceSearch=null;" type="text"
                      class="form-control border-start-1" placeholder="Add Device by EUI or Serial Number..." />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="input-group-text ms-0 bg-white border-0" v-if="status !== 'ready'">
                    <div class="text-success" v-if="status==='device_found'">
                      <i class="bi bi-check-circle"></i>
                      Device Found!!
                    </div>
                    <div class="text-warning" v-if="status==='device_duplicate'">
                      <i class="bi bi-exclamation-circle"></i>
                      Device Already Added!!
                    </div>
                    <div class="text-danger" v-if="status==='device_invalid'">
                      <i class="bi bi-x-circle"></i>
                      Invalid Device!
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="input-group">
                <button class="btn btn-secondary border w-100" type="button" @click="selectCSV();">
                  <span class="bi bi-upload">
                    Upload CSV
                  </span>
                </button>
                <input type="file" class="file" id="csvFile" style="display: none;" accept=".csv" @change="uploadCSV()"/>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-md">
              <div class="card">
                <div class="list-group list-group-flush">
                  <div class="list-group-item bg-light">
                    <div class="row">
                      <div class="col-md border-end d-flex justify-content-between">
                        <span>Device</span>
                        <i class="bi bi-arrow-down-up"></i>
                      </div>
                      <div class="col-md border-end d-flex justify-content-between">
                        <span>Assignee</span>
                        <i class="bi bi-person-badge"></i>
                      </div>
                      <div class="col-md d-flex justify-content-between">
                        <span>Tags / Last Updated</span>
                        <i class="bi bi-bookmark"></i>
                      </div>
                    </div>
                  </div>

                  <div v-if="devices.length==0">
                    <div class="text-center">
                      <img src="/images/icons/empty.svg" class="img-fluid" />
                    </div>
                  </div>
                  <div
                    v-for="device in devices"
                    v-bind:key="device.id"
                    class="list-group-item list-group-item-action">
                    <div class="row">
                      <div class="col-md border-end">
                        <div class="d-flex align-items-center">
                          <div class="flex-shrink-0">
                            <i
                              class="bi fs-4"
                              :class="{
                                'bi-wifi': device.type == 'gateway',
                                'bi-cpu': device.type == 'module',
                                'bi-thermometer-half': device.type == 'sensor',
                              }">
                            </i>
                          </div>
                          <div class="flex-grow-1 ms-3">
                            <b class="text-dark font-monospace small">
                              {{ device.eui }}
                              <i class="bi bi-x-lg text-danger" @click="remove_device(device)" role="button"></i>
                            </b>
                            <p class="small text-muted mb-0">{{ device.name }}</p>
                          </div>
                          <div class="flex-shrink-0">
                            <i
                              class="bi fs-5"
                              :class="{
                                'bi-check-circle text-success': device.status == 'deployed',
                                'bi-plus-circle text-success': device.status == 'ready',
                                'bi-exclamation-circle text-warning': device.status == 'undeployable',
                                'bi-slash-circle text-danger': device.status == 'broken',
                                'bi-x-circle text-danger': device.status == 'lost',
                              }"
                              data-bs-toggle="tooltip" data-bs-placement="top"
                              :data-bs-original-title="getStatusName(device.status)"
                              title="">
                            </i>
                          </div>
                        </div>
                      </div>
                      <div class="col-md border-end d-flex justify-content-between">
                        <div>
                          <b class="d-block">
                            {{ device.assignee ? device.assignee.name : "N/A" }}
                          </b>
                          <p class="small text-muted mb-0">
                            {{ device.organisation ? device.organisation.name : "N/A" }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md">
                        <b class="d-block">
                          <span
                            v-for="tag in device.tags"
                            v-bind:key="tag.id"
                            class="badge bg-light border text-dark me-1"
                            >{{ tag.name }}</span>
                        </b>
                        <p class="small text-muted mb-0">
                          {{ localMachineTime(device.updated_at) }}
                          <span class="float-end">{{
                            timeAgo(device.updated_at)
                          }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <ModalBulkActionsClearList id="clearList" />
  <ModalBulkActionCheckout id="bulkActionCheckout" :devices="devices" />
  <ModalBulkActionCheckin id="bulkActionCheckin" :devices="devices"/>
  <ModalBulkActionStatusUpdate id="bulkActionStatusUpdate" :devices="devices"/>
</template>

<script>
import axios from "axios";
import Storage from "../../helpers/storage";
import Utils from "../../helpers/utils";

import { QrcodeStream } from 'vue3-qrcode-reader'
import ModalBulkActionsClearList from "./BulkActionsClearList.vue"
import ModalBulkActionCheckout from "./BulkActionCheckout.vue"
import ModalBulkActionCheckin from "./BulkActionCheckin.vue"
import ModalBulkActionStatusUpdate from "./BulkActionStatusUpdate.vue"

export default {
  name: "BulkActions",
  components: {
    QrcodeStream,
    ModalBulkActionsClearList,
    ModalBulkActionCheckout,
    ModalBulkActionCheckin,
    ModalBulkActionStatusUpdate
  },
  data() {
    return {
      devices: [],

      camera: 'off',
      status: 'loading',

      deviceSearch: null
    };
  },
  mounted() {
    this.emitter.on("show_bulk_actions", (data) => {
        this.devices = this.local_devices;
        this.camera = 'auto';
        $('#bulkActions').modal('show');
    });

    this.emitter.on("bulk_actions_clear_list", (success) => {
      if (success) {
        this.devices = [];
        this.local_devices = [];
      }
      this.camera = 'auto';
      $('#bulkActions').modal('show');
    });

    this.emitter.on("bulk_actions_assigned", (success) => {
      if (success) {
        this.devices = [];
        this.local_devices = [];
      } else {
        this.camera = 'auto';
        $('#bulkActions').modal('show');
      }
    });

    var bulkActionsModal = document.getElementById('bulkActions');
    bulkActionsModal.addEventListener('hidden.bs.modal', function (event) {
      this.camera = 'off';
    }.bind(this))
  },
  computed: {
    local_devices: {
      get() {
        return Storage.get("bulk_action_devices", true) || [];
      },
      set(newValue) {
        Storage.set("bulk_action_devices", JSON.stringify(newValue));
      }
    },
    gateways_count: function () {
      var gateways = this.devices.filter((device) => device.type == 'gateway');
      return gateways.length;
    },
    modules_count: function () {
      var modules = this.devices.filter((device) => device.type == 'module');
      return modules.length;
    },
    sensors_count: function () {
      var sensors = this.devices.filter((device) => device.type == 'sensor');
      return sensors.length;
    },
  },
  methods: {
    async onInit (promise) {
      this.status = 'loading';

      try {
        await promise;
      } catch (error) {
        console.error(error);
      } finally {
        this.status = 'ready';
      }
    },
    async onDecode(result) {
      if (result != '') {
        // show loading spinner
        this.update_status('loading_device', false);

        this.add_device(result);
      }
    },
    async update_status(status, delay = true, ms = 3000) {
        this.status = status;

        if (delay) {
          await this.timeout(ms)
          this.camera = 'auto';
          this.status = 'ready';
        }
    },
    add_device(device_eui) {
      var sessionTokens = Storage.get("session_tokens", true);
      var umsTokens = Storage.get("tokens");

      var _self = this;
      axios.get(process.env.MIX_APP_URL + "/api/v1/devices/" + device_eui, {
        headers: {
          Authorization: sessionTokens.token_type + " " + sessionTokens.access_token,
          "UMS-Authorization": umsTokens,
        }}).then(
          async (response) => {
            let device = response.data;
            let found = this.devices.findIndex(function(item) {
              return item.eui == device.eui;
            });

            if (found !== -1) {
              this.update_status('device_duplicate');
            } else {
              this.update_status('device_found');
              // this.add_device(device);
              _self.devices.push(device);
              _self.local_devices = _self.devices;
            }
          },
          async (error) => {
            this.update_status('device_invalid');
            console.log('Error: ', error.response);
          }
        );
    },
    remove_device(device) {
      this.devices = this.devices.filter(function( item ) {
        return item.eui !== device.eui;
      });
      this.local_devices = this.devices;
    },
    check_out() {
      console.log('Check out: ', this.devices)
    },
    check_in() {
      console.log('Check in: ', this.devices)
    },
    getStatusName(status) {
      switch (status) {
        case 'deployed':  
          return 'Deployed';
        case 'ready':  
          return 'Ready to deploy';
        case 'undeployable':  
          return 'Undeployable';
        case 'broken':  
          return 'Broken';
        case 'lost':  
          return 'Lost';
        default:
          return 'Unknown';
      }
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    timeout (ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms)
      })
    },
    selectCSV() {
      document.getElementById('csvFile').click()
    },
    uploadCSV() {
      var csvFile = document.getElementById('csvFile');

      var input = csvFile.files[0];
      var reader = new FileReader();

      // function to parse the csv file and add the device(s)
      var _self = this;
      reader.onload = function (e) {
        var text = e.target.result;

        // parse text to array
        var devices = text.split('\n');
        devices.forEach(device => {
          if (device)
          _self.add_device(device)
        });
      };

      // read file
      reader.readAsText(input);

      // clear file input so we're ready to accept another file
      document.getElementById('csvFile').value= null;
    }
  }
};
</script>
