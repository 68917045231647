<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Factory API Token</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
                <div class="alert alert-warning">
                    Here is your new API token.
                        <strong>This is the only time the token will ever be displayed, so be sure not to lose it!</strong>
                </div>

                <textarea id="api-token" class="form-control"
                            @click="copy_token"
                            rows="5">{{ api_token }}
                </textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="copy_token">
            Copy To Clipboard
          </button>
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FactoryAPIToken",
  props: ["id", "api_token"],
  methods: {
    copy_token() {
      var app = this;

      try {
        navigator.clipboard.writeText(this.api_token).then(function () {
          app.emitter.emit("popup_alert", {
            type: "success",
            text: "Factory API Token was successfully copied to clipboard!",
          });
        });
      } catch (err) {
        app.emitter.emit("popup_alert", {
          type: "warning",
          text: "Your browser does not allow copying to clipboard or the application is loaded from non-secure context (HTTPS)",
        });
      }
    }
  }
};
</script>