<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Check Out Device</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="name" class="form-label">Organisation</label>

              <div class="input-group mb-1">
                <span class="input-group-text bg-white border-end-0"
                  ><i class="bi bi-search"></i></span
                ><input
                  type="text"
                  class="form-control border-start-0"
                  placeholder="Filter..."
                  v-model="filter.organisation_name"
                  v-on:keyup="filter_organisations"
                />
              </div>

              <select class="form-control" v-model="organisation_ref">
                <option value="">Please select</option>
                <option
                  v-for="organisation in organisations_filtered"
                  v-bind:key="organisation.id"
                  :value="organisation.id"
                >
                  {{ organisation.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label for="name" class="form-label">Assignee</label>

              <div class="input-group mb-1">
                <span class="input-group-text bg-white border-end-0"
                  ><i class="bi bi-search"></i></span
                ><input
                  type="text"
                  class="form-control border-start-0"
                  placeholder="Filter..."
                  v-model="filter.assignee_name"
                  v-on:keyup="filter_assignees"
                />
              </div>

              <select class="form-control" v-model="assignee_id">
                <option value="">Please select</option>
                <option
                  v-for="assignee in assignees_filtered"
                  v-bind:key="assignee.id"
                  :value="assignee.id"
                >
                  {{ assignee.first_name + " " + assignee.last_name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary w-100"
                @click="checkout_device"
              >
                Checkout Device
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

export default {
  name: "DeviceCheckout",
  props: ["id"],
  data() {
    return {
      eui: "",
      organisation_ref: "",
      assignee_id: "",
      organisations: [],
      organisations_filtered: [],
      assignees: [],
      assignees_filtered: [],
      filter: {
        organisation_name: "",
        assignee_name: "",
      },
    };
  },
  mounted() {
    this.emitter.on("device_details_loaded", (data) => {
      this.eui = data.eui ? data.eui : "";
      this.organisation_ref = data.organisation ? data.organisation.id : "";
      this.assignee_id = data.assignee ? data.assignee.id : "";
    });

    this.fetch_organisations();
  },
  methods: {
    fetch_organisations() {
      var umsTokens = Storage.get("tokens", true);

      axios
        .get(process.env.MIX_RT_UMS_URL + "organizations?items=10000", {
          headers: {
            Authorization: umsTokens.access_token,
          },
        })
        .then((response) => {
          this.organisations = response.data.data.sort((one, two) => {
            return one.name > two.name;
          });

          this.organisations_filtered = this.organisations;

          this.assignee_id = "";

          this.fetch_assignees();
        })
        .catch((error) => {
          process.appdata.refreshTokens();
        });
    },
    fetch_assignees() {
      var umsTokens = Storage.get("tokens", true);

      if ("" === this.organisation_ref) {
        return;
      }

      axios
        .get(
          process.env.MIX_RT_UMS_URL +
            "organizations/" +
            this.organisation_ref +
            "/users?items=10000",
          {
            headers: {
              Authorization: umsTokens.access_token,
            },
          }
        )
        .then((response) => {
          if (response.data.length > 0) {
            this.assignees = response.data[0].users
              .filter((item) => !!item)
              .sort((one, two) => {
                return one.username > two.username;
              });

            this.assignees_filtered = this.assignees;
          } else {
            this.assignees = [];
            this.assignees_filtered = [];
          }
        })
        .catch((error) => {
          process.appdata.refreshTokens();
        });
    },
    filter_organisations() {
      if ("" !== this.filter.organisation_name) {
        this.organisation_ref = "";

        this.organisations_filtered = this.organisations.filter((item) => {
          return (
            item.name
              .toLowerCase()
              .indexOf(this.filter.organisation_name.toLowerCase()) > -1
          );
        });
      } else {
        this.organisations_filtered = this.organisations;
      }
    },
    filter_assignees() {
      if ("" !== this.filter.assignee_name) {
        this.assignee_ref = "";

        this.assignees_filtered = this.assignees.filter((item) => {
          return (
            (item.first_name + " " + item.last_name)
              .toLowerCase()
              .indexOf(this.filter.assignee_name.toLowerCase()) > -1
          );
        });
      } else {
        this.assignees_filtered = this.assignees;
      }
    },
    checkout_device() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens", true);

      if ("" === this.organisation_ref || "" === this.assignee_id) {
        return;
      }

      axios
        .post(
          process.env.MIX_APP_URL +
            "/api/v1/devices/" +
            this.eui +
            "/check_out",
          {
            assignee_id: this.assignee_id,
          },
          {
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
              "UMS-Authorization": umsTokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              this.emitter.emit("device_checked_out");

              let deviceCheckoutModal = bootstrap.Modal.getInstance(
                document.getElementById(this.id)
              );
              deviceCheckoutModal.hide();

              this.emitter.emit("popup_alert", {
                type: "success",
                text: "Device was succesfuly checked out!",
              });
            }
          },
          (error) => {
            this.emitter.emit("popup_alert", {
              type: "danger",
              text: error.message,
            });
          }
        );
    },
  },
  watch: {
    organisation_ref: function () {
      this.fetch_assignees();
    },
  },
};
</script>