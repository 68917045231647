<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update Devices</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="name" class="form-label">Device Status</label
              ><select class="form-control" v-model="status">
                <option value="deployed">Deployed</option>
                <option value="ready">Ready to Deploy</option>
                <option value="undeployable">Undeployable</option>
                <option value="broken">Broken</option>
                <option value="lost">Lost</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" @click="update_devices(false)">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-primary w-100" @click="update_devices(true)">
                Update
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

export default {
  name: "BulkActionStatusUpdate",
  props: ["id", "devices"],
  data() {
    return {
      status: ''
    };
  },
  methods: {
    update_devices(status) {
      var bulkActionStatusUpdateModal = bootstrap.Modal.getInstance(
        document.getElementById(this.id)
      );

      if (status === true) {
        let payload = this.devices.map(function(device) {
          return device.eui;
        });

        var sessionTokens = Storage.get("session_tokens", true),
          umsTokens = Storage.get("tokens");

        axios
          .post(
            process.env.MIX_APP_URL + "/api/v1/devices/update_status",
            {devices: payload, status: this.status},
            {
              headers: {
                Authorization:
                  sessionTokens.token_type + " " + sessionTokens.access_token,
                "UMS-Authorization": umsTokens,
              },
            }
          )
          .then((response) => {
            if (200 === response.status) {
              bulkActionStatusUpdateModal.hide();

              this.emitter.emit("bulk_actions_assigned", status);
              this.emitter.emit("popup_alert", {
                type: "success",
                text: "Devices were succesfuly updated!",
              });
            } else {
              this.emitter.emit("popup_alert", {
                type: "danger",
                text: response.message,
              });
            }
          });
      } else {
        bulkActionStatusUpdateModal.hide();
        this.emitter.emit("bulk_actions_assigned", status);
      }
    },
    capitalizeText(text) {
      return Utils.capitalizeText(text);
    },
  },
};
</script>