<template>
  <div class="card">
    <div class="card-header bg-white">
      <i class="bi bi-bar-chart-line-fill me-2"></i>
      Analytics
    </div>
    <div class="card-body">
      <div class="text-center">
        <img src="/images/icons/empty.svg" class="img-fluid py-5 w-25" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Utils from "../../helpers/utils";

export default {
  name: "FactoryAnalytics",
  components: {},
  data() {
    return {
      analytics: {},
    };
  },

  methods: {},
};
</script>
