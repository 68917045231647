<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">New Device Model</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="name" class="form-label">Name</label>
              <input
                type="text"
                id="name"
                class="form-control"
                v-model="name"
                @keyup="validate"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label for="ref" class="form-label">Reference</label>
              <input
                type="text"
                id="ref"
                class="form-control"
                v-model="ref"
                @keyup="validate"
              />
              <small class="text-secondary"
                ><i>Must be 4 characters from 0 to 9 and A to F</i></small
              >
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label for="region" class="form-label">Type</label>
              <select
                id="type"
                v-model="type"
                class="form-control"
                @change="validate"
              >
                <option value="">Please select</option>
                <option value="gateway">Gateway</option>
                <option value="module">Module</option>
                <option value="sensor">Sensor</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary w-100"
                :disabled="!valid_input"
                @click="add_device_model"
              >
                Add device model
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

export default {
  name: "NewDeviceModel",
  props: ["id"],
  data() {
    return {
      ref: "",
      name: "",
      type: "",
      valid_input: false,
    };
  },
  methods: {
    validate() {
      this.valid_input = this.ref.length == 4 && this.name.length >= 0 && this.type !== "";
    },

    add_device_model() {
      let app = this;

      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .post(
          process.env.MIX_APP_URL + "/api/v1/models/",
          {
            ref: this.ref,
            name: this.name,
            type: this.type
          },
          {
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
              "UMS-Authorization": umsTokens,
            },
          }
        )
        .then((response) => {
          this.emitter.emit("device_model_created");

          let newDeviceModelModal = bootstrap.Modal.getInstance(
            document.getElementById(this.id)
          );
          newDeviceModelModal.hide();

          this.emitter.emit("popup_alert", {
            type: "success",
            text: "Device Model was succesfuly created!",
          });
        })
        .catch(function (error) {
          if (error.response) {
            let errorMsg = "";

            for (let key in error.response.data) {
              errorMsg += error.response.data[key].join(" ");
            }

            app.emitter.emit("popup_alert", {
              type: "danger",
              text: errorMsg,
            });
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    },
  },
};
</script>