<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update Factory Name</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="name" class="form-label">Factory Name</label
              ><input
                type="text"
                id="name"
                minlength="3"
                maxlength="50"
                class="form-control"
                v-model="name"
                @keyup="validate"
              />
              <small class="text-secondary">
                <i>Must be at least 3 characters</i></small
              >
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary w-100"
                :disabled="!valid_input"
                @click="update_factory"
              >
                Update Factory
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

export default {
  name: "FactoryName",
  props: ["id"],
  data() {
    return {
      ref: "",
      name: "",
      valid_input: false,
    };
  },
  mounted() {
    this.emitter.on("factory_details_loaded", (data) => {
      this.ref = data.ref ? data.ref : "";
      this.name = data.name ? data.name : "";

      this.validate();
    });
  },
  methods: {
    validate() {
      this.valid_input = this.name.length >= 3;
    },

    update_factory() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .patch(
          process.env.MIX_APP_URL + "/api/v1/factories/" + this.ref,
          {
            name: this.name,
          },
          {
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
              "UMS-Authorization": umsTokens,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              this.emitter.emit("factory_details_updated");

              let factoryNameModal = bootstrap.Modal.getInstance(
                document.getElementById(this.id)
              );
              factoryNameModal.hide();

              this.emitter.emit("popup_alert", {
                type: "success",
                text: "Factory name was succesfuly updated!",
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>