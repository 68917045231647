<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Check In Devices</h5>
          <button
            type="button"
            class="btn-close"
            @click="bulk_checkin(false)"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-warning text-center">
                Are you sure you wish to check-in all the selected devices?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" @click="bulk_checkin(false)">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-warning w-100" @click="bulk_checkin(true)">
                Confirm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

export default {
  name: "BulkActionCheckin",
  props: ["id", "devices"],
  methods: {
    bulk_checkin(status) {
      var bulkActionCheckinModal = bootstrap.Modal.getInstance(
        document.getElementById(this.id)
      );

      if (status === true) {
        let payload = this.devices.map(function(device) {
          return device.eui;
        });

        var sessionTokens = Storage.get("session_tokens", true),
          umsTokens = Storage.get("tokens");

        axios
          .post(
            process.env.MIX_APP_URL + "/api/v1/devices/check_in",
            {devices: payload},
            {
              headers: {
                Authorization:
                  sessionTokens.token_type + " " + sessionTokens.access_token,
                "UMS-Authorization": umsTokens,
              },
            }
          )
          .then((response) => {
            if (200 === response.status) {
              bulkActionCheckinModal.hide();

              this.emitter.emit("bulk_actions_assigned", status);
              this.emitter.emit("popup_alert", {
                type: "success",
                text: "Devices were succesfuly checked in!",
              });
            } else {
              this.emitter.emit("popup_alert", {
                type: "danger",
                text: response.message,
              });
            }
          });
      } else {
        bulkActionCheckinModal.hide();
        this.emitter.emit("bulk_actions_assigned", status);
      }
    },
  },
};
</script>