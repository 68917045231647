<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Check In Device</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-warning text-center">
                Are you sure you wish to check-in the device?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-warning w-100" @click="checkin_device()">
                Confirm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

export default {
  name: "DeviceCheckin",
  props: ["id"],
  data() {
    return {
      eui: "",
    };
  },
  mounted() {
    this.emitter.on("device_details_loaded", (data) => {
      this.eui = data.eui ? data.eui : "";
    });
  },
  methods: {
    checkin_device() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens", true);

      axios
        .post(
          process.env.MIX_APP_URL + "/api/v1/devices/" + this.eui + "/check_in",
          {},
          {
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
              "UMS-Authorization": umsTokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.emitter.emit("device_checked_in");

            let deviceCheckinModal = bootstrap.Modal.getInstance(
              document.getElementById(this.id)
            );
            deviceCheckinModal.hide();

            this.emitter.emit("popup_alert", {
              type: "success",
              text: "Device was succesfuly checked in!",
            });
          } else {
            this.emitter.emit("popup_alert", {
              type: "danger",
              text: response.message,
            });
          }
        });
    },
  },
};
</script>
