<template>
  <Breadcrumbs :breaditems="[{ title: 'Dashboard' }]" />
  <div class="container-xl">
    <div class="d-flex mb-2">
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1"><i class="bi bi-bookmark"></i></span>
          <select v-model="filter.type" class="form-select bg-white border-start-0 ps-0" style="max-width: 125px">
            <option disabled="">Device Model</option>
            <option value="">All</option>
            <option value="gateway">Gateways</option>
            <option value="module">Modules</option>
            <option value="sensor">Sensors</option>
          </select>
        </div>
      </div>
      <div class="d-flex ms-auto">
        <div class="input-group">
          <span class="input-group-text bg-white border-end-0"><i class="bi bi-search"></i></span>
          <input v-model="filter.search" v-on:keyup.enter="fetch_stats()" type="text"
            class="form-control border-start-0" placeholder="Search..." />
        </div>
      </div>
    </div>

    <div class="card border-bottom-0">
      <div class="table-responsive">
        <table class="table mb-0" id="tblDashboard">
          <thead class="bg-light border-bottom">
            <th class="border-end text-nowrap p-2 fw-normal">Product</th>
            <th class="border-end text-center text-nowrap p-2 fw-normal">Ready to deploy</th>
            <th class="border-end text-center text-nowrap p-2 fw-normal">Deployed</th>
            <th class="border-end text-center text-nowrap p-2 fw-normal">Undeployable</th>
            <th class="border-end text-center text-nowrap p-2 fw-normal">Broken</th>
            <th class="border-end text-center text-nowrap p-2 fw-normal">Lost</th>
            <th class="text-center text-nowrap p-2 fw-normal">Total</th>
          </thead>

          <tbody class="border-top-0">
            <tr v-for="stat in stats" v-bind:key="stat.ref">
              <td class="border-end">
                <b class="text-dark text-nowrap font-monospace small">{{ stat.name }}</b>
                <p class="small d-flex text-muted mb-0">
                  {{ stat.ref }}
                </p>
              </td>
              <td class="border-end">
                <div class="d-inline-block w-50 text-nowrap">
                  <b class="d-block"><i class="bi bi-hash"></i></b>
                  <small class="d-block text-muted"><i class="bi bi-percent"></i></small>
                </div>
                <div class="text-end d-inline-block w-50 text-nowrap">
                  <span class="d-block fw-bold text-primary">{{
                      stat.ready || 0
                  }}</span>
                  <span class="d-block fw-bold small text-muted">{{
                      formatShare(stat.ready || 0, stat.total || 0)
                  }}</span>
                </div>
              </td>
              <td class="border-end">
                <div class="d-inline-block w-50 text-nowrap">
                  <b class="d-block"><i class="bi bi-hash"></i></b>
                  <small class="d-block text-muted"><i class="bi bi-percent"></i></small>
                </div>
                <div class="text-end d-inline-block w-50 text-nowrap">
                  <span class="d-block fw-bold text-success">{{
                      stat.deployed || 0
                  }}</span>
                  <span class="d-block fw-bold small text-muted">{{
                      formatShare(stat.deployed || 0, stat.total || 0)
                  }}</span>
                </div>
              </td>
              <td class="border-end">
                <div class="d-inline-block w-50 text-nowrap">
                  <b class="d-block"><i class="bi bi-hash"></i></b>
                  <small class="d-block text-muted"><i class="bi bi-percent"></i></small>
                </div>
                <div class="text-end d-inline-block w-50 text-nowrap">
                  <span class="d-block fw-bold text-warning">{{
                      stat.undeployable || 0
                  }}</span>
                  <span class="d-block fw-bold small text-muted">{{
                      formatShare(stat.undeployable || 0, stat.total || 0)
                  }}</span>
                </div>
              </td>
              <td class="border-end">
                <div class="d-inline-block w-50 text-nowrap">
                  <b class="d-block"><i class="bi bi-hash"></i></b>
                  <small class="d-block text-muted"><i class="bi bi-percent"></i></small>
                </div>
                <div class="text-end d-inline-block w-50 text-nowrap">
                  <span class="d-block fw-bold text-danger">{{
                      stat.broken || 0
                  }}</span>
                  <span class="d-block fw-bold small text-muted">{{
                      formatShare(stat.broken || 0, stat.total || 0)
                  }}</span>
                </div>
              </td>
              <td class="border-end">
                <div class="d-inline-block w-50 text-nowrap">
                  <b class="d-block"><i class="bi bi-hash"></i></b>
                  <small class="d-block text-muted"><i class="bi bi-percent"></i></small>
                </div>
                <div class="text-end d-inline-block w-50 text-nowrap">
                  <span class="d-block fw-bold text-danger">{{
                      stat.lost || 0
                  }}</span>
                  <span class="d-block fw-bold small text-muted">{{
                      formatShare(stat.lost || 0, stat.total || 0)
                  }}</span>
                </div>
              </td>
              <td>
                <div class="d-inline-block w-50 text-nowrap">
                  <b class="d-block"><i class="bi bi-hash"></i></b>
                  <small class="d-block text-muted"><i class="bi bi-percent"></i></small>
                </div>
                <div class="text-end d-inline-block w-50 text-nowrap">
                  <span class="d-block fw-bold">{{ stat.total || 0 }}</span>
                  <span class="d-block fw-bold small text-muted">{{
                      formatShare(stat.total || 0, stat.total || 0)
                  }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!--
      <div class="list-group list-group-flush">
        <div class="list-group-item bg-light">
          <div class="row">
            <div class="col-md-3 border-end">
              <span>Product</span>
            </div>
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-2 border-end text-center">
                  <span>Ready to deploy</span>
                </div>
                <div class="col-md-2 border-end text-center">
                  <span>Deployed</span>
                </div>
                <div class="col-md-2 border-end text-center">
                  <span>Undeployable</span>
                </div>
                <div class="col-md-2 border-end text-center">
                  <span>Broken</span>
                </div>
                <div class="col-md-2 border-end text-center">
                  <span>Lost</span>
                </div>
                <div class="col-md-2 text-center">
                  <span>Total</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="stat in stats"
          v-bind:key="stat.ref"
          class="list-group-item"
        >
          <div class="row">
            <div class="col-md-3 border-end">
              <b class="text-dark font-monospace small">{{ stat.name }}</b>
              <p class="small d-flex text-muted mb-0">
                {{ stat.ref }}
              </p>
            </div>
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-2 border-end d-flex justify-content-between">
                  <div>
                    <b class="d-block"><i class="bi bi-hash"></i></b>
                    <small class="d-block text-muted"
                      ><i class="bi bi-percent"></i
                    ></small>
                  </div>
                  <div class="text-end">
                    <span class="d-block fw-bold text-primary">{{
                      stat.ready || 0
                    }}</span>
                    <span class="d-block fw-bold small text-muted">{{
                      formatShare(stat.ready || 0, stat.total || 0)
                    }}</span>
                  </div>
                </div>
                <div class="col-md-2 border-end d-flex justify-content-between">
                  <div>
                    <b class="d-block"><i class="bi bi-hash"></i></b>
                    <small class="d-block text-muted"
                      ><i class="bi bi-percent"></i
                    ></small>
                  </div>
                  <div class="text-end">
                    <span class="d-block fw-bold text-success">{{
                      stat.deployed || 0
                    }}</span>
                    <span class="d-block fw-bold small text-muted">{{
                      formatShare(stat.deployed || 0, stat.total || 0)
                    }}</span>
                  </div>
                </div>
                <div class="col-md-2 border-end d-flex justify-content-between">
                  <div>
                    <b class="d-block"><i class="bi bi-hash"></i></b>
                    <small class="d-block text-muted"
                      ><i class="bi bi-percent"></i
                    ></small>
                  </div>
                  <div class="text-end">
                    <span class="d-block fw-bold text-warning">{{
                      stat.undeployable || 0
                    }}</span>
                    <span class="d-block fw-bold small text-muted">{{
                      formatShare(stat.undeployable || 0, stat.total || 0)
                    }}</span>
                  </div>
                </div>
                <div class="col-md-2 border-end d-flex justify-content-between">
                  <div>
                    <b class="d-block"><i class="bi bi-hash"></i></b>
                    <small class="d-block text-muted"
                      ><i class="bi bi-percent"></i
                    ></small>
                  </div>
                  <div class="text-end">
                    <span class="d-block fw-bold text-danger">{{
                      stat.broken || 0
                    }}</span>
                    <span class="d-block fw-bold small text-muted">{{
                      formatShare(stat.broken || 0, stat.total || 0)
                    }}</span>
                  </div>
                </div>
                <div class="col-md-2 border-end d-flex justify-content-between">
                  <div>
                    <b class="d-block"><i class="bi bi-hash"></i></b>
                    <small class="d-block text-muted"
                      ><i class="bi bi-percent"></i
                    ></small>
                  </div>
                  <div class="text-end">
                    <span class="d-block fw-bold text-danger">{{
                      stat.lost || 0
                    }}</span>
                    <span class="d-block fw-bold small text-muted">{{
                      formatShare(stat.lost || 0, stat.total || 0)
                    }}</span>
                  </div>
                </div>
                <div class="col-md-2 d-flex justify-content-between">
                  <div>
                    <b class="d-block"><i class="bi bi-hash"></i></b>
                    <small class="d-block text-muted"
                      ><i class="bi bi-percent"></i
                    ></small>
                  </div>
                  <div class="text-end">
                    <span class="d-block fw-bold">{{ stat.total || 0 }}</span>
                    <span class="d-block fw-bold small text-muted">{{
                      formatShare(stat.total || 0, stat.total || 0)
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../helpers/storage";
import Breadcrumbs from "../components/Breadcrumbs.vue";

export default {
  name: "Dashboard",
  components: { Breadcrumbs },
  data() {
    return {
      stats: [],
      filter: {
        type: "",
        search: "",
      },
      edit_device_model: {}
    };
  },
  mounted() {
    this.fetch_stats();
  },
  methods: {
    fetch_stats() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens"),
        url =
          process.env.MIX_APP_URL +
          "/api/v1/dashboard?type=" +
          this.filter.type +
          "&search=" +
          encodeURIComponent(this.filter.search);

      axios
        .get(url, {
          headers: {
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          this.stats = response.data;
        });
    },

    formatShare(number, total) {
      let numberFloat = parseFloat(number),
        totalFloat = parseFloat(total);

      if (0 === totalFloat) {
        return "N/A";
      } else {
        return Math.round(numberFloat / totalFloat) * 100 + "%";
      }
    },
  },
  watch: {
    "filter.type": function () {
      this.fetch_stats();
    },
  },
};
</script>