<template>
  <div class="card mb-2">
    <div class="card-header bg-white">
      <i class="bi bi-app-indicator me-2"></i>
      Overview
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-tools me-3"></i>Name:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="align-self-center small font-monospace">{{
              factory.name
            }}</span>
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#factoryName"
            >
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-hash me-3"></i>ID:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="align-self-center small font-monospace">{{
              factory.ref
            }}</span>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-map me-3"></i>Region:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="align-self-center">{{ factory.region }}</span>
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#factoryRegion"
            >
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-key me-3"></i>API key:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="align-self-center">{{api_token ? api_token : '***********'}}</span>
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#factoryAPITokenRegen"
            >
              Regenerate
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-calendar-day me-3"></i>Created:
          </div>
          <div class="w-75 py-2 px-3 small align-self-center">
            {{ localMachineTime(factory.created_at) }}
            <span class="float-end">{{ timeAgo(factory.created_at) }}</span>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-calendar-day-fill me-3"></i>Updated:
          </div>
          <div class="w-75 py-2 px-3 small align-self-center">
            {{ localMachineTime(factory.updated_at) }}
            <span class="float-end">{{ timeAgo(factory.updated_at) }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <ModalFactoryName id="factoryName" />
  <ModalFactoryRegion id="factoryRegion" />
  <ModalFactoryAPITokenRegen id="factoryAPITokenRegen" />
</template>

<script>
import axios from "axios";

import Utils from "../../helpers/utils";
import Storage from "../../helpers/storage";

import ModalFactoryName from "../../components/modals/FactoryName.vue";
import ModalFactoryRegion from "../../components/modals/FactoryRegion.vue";
import ModalFactoryAPITokenRegen from "../../components/modals/FactoryAPITokenRegen.vue";

export default {
  name: "FactoryOverview",
  components: { ModalFactoryName, ModalFactoryRegion, ModalFactoryAPITokenRegen },
  data() {
    return {
      factory: {
        ref: this.$route.params.factory,
        user: {},
      },
      api_token: null
    };
  },
  mounted() {
    this.emitter.on("factory_details_updated", (data) => {
      this.fetch_factory();
    });

    this.emitter.on("factory_api_token_regen", (data) => {
      this.api_token = data
    });

    this.fetch_factory();
  },
  methods: {
    fetch_factory() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .get(
          process.env.MIX_APP_URL + "/api/v1/factories/" + this.factory.ref,
          {
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
              "UMS-Authorization": umsTokens,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.emitter.emit("factory_details_loaded", response.data);

            this.factory = response.data;
          } else {
            process.appdata.umslogin();
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 404) {
            console.log('Error', error.message);
            router.push({name: 'NotFound' })
          }
        });
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
  },
};
</script>
