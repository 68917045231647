<template>
  <Breadcrumbs
    :breaditems="[
      { title: 'Devices', link: '/devices' },
      { title: device.name, link: '/devices/' + device.eui },
    ]"
  />
  <div class="container-xl">
    <div class="card">
      <div class="card-header bg-white">
        <i class="bi bi-app-indicator me-2"></i>
        Overview
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <ul class="list-group">
              <li class="list-group-item d-flex p-0 border-0">
                <div class="w-50 py-2 px-3 bg-light">
                  <i class="bi bi-hash me-3"></i>Device ID / EUI:
                </div>
                <div class="w-50 py-2 px-3">
                  <span class="align-self-center small font-monospace">{{
                    device.eui
                  }}</span>
                  <button
                    type="button"
                    class="btn btn-link p-0 float-end text-decoration-none"
                    @click="copy_eui"
                  >
                    Copy
                  </button>
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0">
                <div class="w-50 py-2 px-3 bg-light">
                  <i class="bi bi-wifi me-3"></i>Model:
                </div>
                <div class="w-50 py-2 px-3 d-flex justify-content-between">
                  {{device.device_model.ref + ' - ' + device.device_model.name}}
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0">
                <div class="w-50 py-2 px-3 bg-light">
                  <i class="bi bi-circle me-3"></i>Status:
                </div>
                <div class="w-50 py-2 px-3 bg-opacity-25 d-flex justify-content-between"
                  :class="{
                    'bg-success': ['deployed', 'ready'].includes(device.status),
                    'bg-warning': device.status == 'undeployable',
                    'bg-danger': ['broken', 'lost'].includes(device.status)
                    }">
                  <span class="w-100" :class="{
                    'text-success': ['deployed', 'ready'].includes(device.status),
                    'text-warning': device.status == 'undeployable',
                    'text-danger': ['broken', 'lost'].includes(device.status)
                    }">
                    {{capitalizeText(device.status)}}
                    <button type="button" class="btn btn-link p-0 float-end text-decoration-none" @click="changeStatus()">
                      Update
                    </button>
                  </span>
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0 mt-1">
                <div class="w-50 py-2 px-3 bg-light">
                  <i class="bi bi-tools me-3"></i>Factory:
                </div>
                <div class="w-50 py-2 px-3">
                  <router-link
                    :to="'/factories/' + device.factory.ref"
                    class="text-decoration-none"
                    >{{
                      device.factory.ref + " - " + device.factory.name
                    }}</router-link
                  >
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0">
                <div class="w-50 py-2 px-3 bg-light">
                  <i class="bi bi-screwdriver me-3"></i>Manufactured:
                </div>
                <div class="w-50 py-2 px-3">
                  <a href="#" class="text-decoration-none"
                    >Week {{ device.manufactured_week }} /
                    {{ device.manufactured_year }}</a
                  >
                  <span class="float-end">~{{
                    timeAgo(device.manufactured_date)
                  }}</span>
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0 mt-1">
                <div class="w-50 py-2 px-3 bg-light">
                  <i class="bi bi-calendar-day me-3"></i>Created:
                </div>
                <div class="w-50 py-2 px-3 small align-self-center">
                  {{ device.created_at }}
                  <span class="float-end">{{
                    timeAgo(device.created_at)
                  }}</span>
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0 mt-1">
                <div class="w-50 py-2 px-3 bg-light">
                  <i class="bi bi-calendar-day-fill me-3"></i>Updated:
                </div>
                <div class="w-50 py-2 px-3 small align-self-center">
                  {{ device.updated_at }}
                  <span class="float-end">{{
                    timeAgo(device.updated_at)
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <div class="list-group">
              <a class="list-group-item list-group-item-action rounded-0 border-0">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0 text-center" style="width: 32px">
                    <i class="bi bi-building fs-4"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <b class="text-dark">{{
                      device.organisation ? device.organisation.name : "N/A"
                    }}</b>
                    <p class="small text-muted mb-0">
                      <span>{{
                        device.organisation ? device.organisation.industry : ""
                      }}</span>
                    </p>
                  </div>
                  <div class="flex-shrink-0">
                    <i class="bi bi-building fs-4"></i>
                  </div>
                </div>
              </a>
              <a class="list-group-item list-group-item-action rounded-0 border-0">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0" style="width: 32px">
                    <img 
                      v-if="device.assignee"
                      :src="device.assignee.picture"
                      class="rounded-circle align-self-center"
                      width="36"
                      height="36"
                      onerror="this.src='/images/icons/person-circle.svg'"
                    />
                    <img
                      v-else
                      src="/images/icons/person-circle.svg"
                      class="rounded-circle align-self-center"
                      width="36"
                      height="36"
                    />
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <b class="text-dark">{{
                      device.assignee ? device.assignee.name : "N/A"
                    }}</b>
                    <p class="small text-muted mb-0">
                      {{
                        device.assignee ? device.assignee.email : ""
                      }}
                    </p>
                  </div>
                  <div class="flex-shrink-0">
                    <i class="bi bi-person-badge fs-4"></i>
                  </div>
                </div>
              </a>
            </div>
            <div>
              <label class="form-label">Notes</label>
              <label v-if="notesSaved" class="form-label float-end text-muted"
                >Saved</label
              >
              <textarea
                v-model="device.notes"
                class="form-control"
                rows="3"
                @keyup="save_notes"
              ></textarea>
            </div>

            <ul class="list-group mt-2">
              <li class="list-group-item d-flex p-0 border-0 mt-1">
                <div class="w-25 py-2 px-3 bg-light">
                  <i class="bi bi bi-bookmark me-3"></i>Tags:
                </div>
                <div class="w-75 py-2 px-3 small align-self-center">
                  <vue-tags-input
                    v-model="tag"
                    :tags="tags"
                    @tags-changed="(newTags) => (tags = newTags)"
                    @before-adding-tag="add_tag"
                    @before-deleting-tag="delete_tag"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-2 mt-0 mb-2">
      <div class="col-3">
        <div class="card h-100">
          <div class="list-group list-group-flush">
            <button
              type="button"
              class="btn btn-link text-decoration-none pt-3"
              data-bs-toggle="modal"
              data-bs-target="#deviceCheckout"
            >
              <div class="d-inline-block">
                <i class="bi bi-pen fs-2 text-danger"></i>
              </div>
              <div class="d-inline-block d-flex">
                <div class="align-self-center mx-auto mt-2">
                  <b class="text-dark">Checkout</b>
                  <p class="small text-muted">Assigned to Person</p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card h-100">
          <div class="list-group list-group-flush">
            <button
              type="button"
              class="btn btn-link text-decoration-none pt-3"
              data-bs-toggle="modal"
              data-bs-target="#deviceCheckin"
              :disabled="device.assignee == null"
            >
              <div class="d-inline-block">
                <i class="bi bi-journal-arrow-down fs-2 text-warning"></i>
              </div>
              <div class="d-inline-block d-flex">
                <div class="align-self-center mx-auto mt-2">
                  <b class="text-dark">Checkin</b>
                  <p class="small text-muted">Device returned</p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card h-100">
          <div class="list-group list-group-flush">
            <button
              type="button"
              class="btn btn-link text-decoration-none pt-3"
              @click.stop.prevent="changeStatus(getNextStatus(device.status))"
              :disabled="device.status=='deployed'"
            >
              <div class="d-inline-block">
                <i class="fs-2 bi" :class="{
                  'bi-check-circle text-secondary': device.status == 'deployed',
                  'bi-plus-circle text-success': ['ready', ,'undeployable', 'broken', 'lost'].includes(device.status)
                }"></i>
              </div>
              <div class="d-inline-block d-flex">
                <div class="align-self-center mx-auto mt-2">
                  <b class="text-dark">Quality Assurance</b>
                  <p class="small text-muted">
                    Device {{ getNextStatus(device.status) }}
                  </p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="card h-100">
          <div class="list-group list-group-flush">
            <button
              type="button"
              class="btn btn-link text-decoration-none pt-3"
              data-bs-toggle="modal"
              data-bs-target="#deviceDelete"
            >
              <div class="d-inline-block">
                <i
                  class="bi bi-x-circle fs-2 text-danger"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  data-bs-original-title="Delete Device"
                ></i>
              </div>
              <div class="d-inline-block d-flex">
                <div class="align-self-center mx-auto mt-2">
                  <b class="text-dark">Delete Device</b>
                  <p class="small text-muted">Remove the device</p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-2">
      <div class="card-header bg-white">
        <i class="bi bi-cpu me-2"></i>
        IoT Core
        <span class="text-muted" v-if="iotcore_device.status == 404"> - device not found.</span>
        <span class="text-secondary" v-else-if="iotcore_device.status == null"> - loading..</span>
        <span class="text-danger" v-else-if="iotcore_device.status != 200"> - an error has occured.</span>
      </div>
      <div class="card-body">
        <div v-if="iotcore_device.status != 200">
          <div class="text-center">
            <img src="/images/icons/empty.svg" class="img-fluid" />
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-md-6">
            <ul class="list-group">
              <li class="list-group-item d-flex p-0 border-0">
                <div class="w-50 py-2 px-3 bg-light">
                  <i class="bi bi-circle-fill me-3"></i>Status:
                </div>
                <div class="w-50 py-2 px-3 d-flex justify-content-between">
                  <span class="fw-bold" :class="{'text-success' : iotcore_device.data.status == 'online', 'text-danger': iotcore_device.data.status != 'online'}"
                    >{{capitalizeText(iotcore_device.data.status)}} 
                  </span>
                  <span
                    class="small align-self-center fw-bold"
                    :class="{'text-success' : iotcore_device.data.status == 'online', 'text-danger': iotcore_device.data.status != 'online'}"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="Uptime"
                    >{{iotcore_device.data.uptime.perc}}%</span
                  >
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0">
                <div class="w-50 py-2 px-3 bg-light">
                  <i class="bi bi-git me-3"></i>Firmware:
                </div>
                <div class="w-50 py-2 px-3 d-flex justify-content-between" v-if="iotcore_device.data.firmware">
                  <span>{{iotcore_device.data.firmware.releaseTitle}}</span>
                  <span class="small align-self-center float-end">{{iotcore_device.data.firmware.version.major + '.' + iotcore_device.data.firmware.version.minor + '.' + iotcore_device.data.firmware.version.patch}}</span>
                </div>
                <div class="w-50 py-2 px-3 d-flex justify-content-between" v-else>
                  n/a
                </div>
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <ul class="list-group">
              <li class="list-group-item d-flex p-0 border-0">
                <div class="w-50 py-2 px-3 bg-light">
                  <i class="bi bi-battery-full me-3"></i>Battery:
                </div>
                <div class="w-50 py-2 px-3 d-flex justify-content-between" v-if="iotcore_device.data.battery_level">
                  <span class="text-success fw-bold">{{iotcore_device.data.battery_level}}%</span>
                  <!-- <span class="small align-self-center fw-bold text-success"
                    >Charging</span
                  > -->
                </div>
                <div class="w-50 py-2 px-3 d-flex justify-content-between" v-else>
                  n/a
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0">
                <div class="w-50 py-2 px-3 bg-light">
                  <i class="bi bi-power me-3"></i>Last Seen:
                </div>
                <div class="w-50 py-2 px-3 d-flex justify-content-between">
                  <span class="small align-self-center"
                    >{{iotcore_device.data.last_seen || 'never'}}</span
                  >
                  <span v-if="iotcore_device.data.last_seen">{{timeAgo(iotcore_device.data.last_seen)}}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex bd-highlight mb-2">
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1"
            ><i class="bi bi-circle"></i
          ></span>
          <select
            v-model="filter.log_type"
            class="form-select bg-white border-start-0 ps-0"
            style="max-width: 225px"
          >
            <option value="---" disabled="">Status</option>
            <option value="">All Logs</option>
            <option value="device_created">Device created</option>
            <option value="device_deleted">Device deleted</option>
            <option value="device_updated">Device updated</option>
          </select>
        </div>
      </div>
      <div class="bd-highlight me-2">
        <div class="input-group">
          <div class="input-group">
            <span class="input-group-text bg-white"
              ><i class="bi bi-calendar-week"></i
            ></span>
            <input
              type="text"
              id="fromDate"
              placeholder="From Date"
              class="form-control datepicker-input"
              style="max-width: 120px"
            />

            <span class="input-group-text bg-white"
              ><i class="bi bi-calendar-week"></i
            ></span>
            <input
              type="text"
              id="toDate"
              placeholder="To Date"
              class="form-control datepicker-input"
              style="max-width: 120px"
            />
          </div>
        </div>
      </div>
      <div class="ms-auto">
        <div class="input-group">
          <span class="input-group-text bg-white border-end-0"
            ><i class="bi bi-search"></i
          ></span>
          <input
            v-model="filter.activity_name"
            type="text"
            class="form-control border-start-0"
            v-on:keyup.enter="fetch_activities()"
            placeholder="Search..."
          />
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header bg-light">Activity Log</div>
      <div v-if="0 === activities.length" class="card-body">
        <div class="text-center">
          <img src="/images/icons/empty.svg" class="img-fluid py-5" />
        </div>
      </div>

      <div v-else class="list-group list-group-flush">
        <router-link
          v-for="activity in activities"
          v-bind:key="activity.id"
          :data-user="activity.causer_id"
          :to="'/logs/' + activity.id"
          class="list-group-item list-group-item-action"
        >
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
              <img
                src="/images/icons/person-circle.svg"
                :alt="activity.causer_name"
                class="rounded-circle align-self-center"
                width="36"
                height="36"
                onerror="this.src='/images/person-circle.svg';this.error=null;"
              />
            </div>
            <div class="flex-grow-1 ms-3">
              <span class="text-dark">{{ activity.causer_name }}</span>
              <p
                class="small text-muted mb-0 fw-normal"
                v-html="activity.plaintext ? activity.plaintext : '&nbsp;'"
              ></p>
            </div>
            <span
              class="text-muted float-end small text-nowrap"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title=""
              :data-bs-original-title="activity.created_at"
              >{{ timeAgo(activity.created_at) }}</span
            >
            <div class="float-end ms-3">
              <i class="bi bi-chevron-right align-self-center"></i>
            </div>
          </div>
        </router-link>
      </div>
      <pagination
        v-if="paginator.current_activity_page"
        :records="paginator.total_items"
        :per-page="10"
        v-model="current_activity_page"
        :options="paginatorOptions"
      ></pagination>
    </div>
  </div>

  <ModalDeviceStatus id="deviceStatus" :nextStatus="nextStatus"/>
  <ModalDeviceCheckout id="deviceCheckout" />
  <ModalDeviceCheckin id="deviceCheckin" />
  <ModalDeviceDelete id="deviceDelete" />
</template>

<script>
import axios from "axios";
import moment from "moment";
import Pagination from "v-pagination-3";
import { Datepicker } from "vanillajs-datepicker";
import VueTagsInput from "@sipec/vue3-tags-input";

import Storage from "../helpers/storage";
import Utils from "../helpers/utils";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import RTPagination from "../components/RTPagination.vue";

import ModalDeviceStatus from "../components/modals/DeviceStatus.vue";
import ModalDeviceCheckout from "../components/modals/DeviceCheckout.vue";
import ModalDeviceCheckin from "../components/modals/DeviceCheckin.vue";
import ModalDeviceDelete from "../components/modals/DeviceDelete.vue";

export default {
  name: "Device",
  components: {
    Breadcrumbs,
    Pagination,
    RTPagination,
    ModalDeviceStatus,
    ModalDeviceCheckout,
    ModalDeviceCheckin,
    ModalDeviceDelete,
    VueTagsInput,
  },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      activities: [],
      filter: {
        activity_name: "",
        log_type: "",
        from_date: "",
        to_date: "",
      },
      paginator: {},
      device: {
        eui: this.$route.params.device,
        factory: {},
        device_model: {},
        assignee: {},
        notes: "",
        manufactured_date: "",
      },
      iotcore_device: {
        status: null,
        data: {}
      },
      tag: "",
      tags: [],
      notesSaved: false,
      current_activity_page: 1,
      saveTimeout: null,
      nextStatus: null
    };
  },
  mounted() {
    let app = this;

    const fromDate = new Datepicker(document.getElementById("fromDate"), {
      clearBtn: true,
      autohide: true,
      maxDate: new Date(),
      format: "d M yyyy",
    });

    const toDate = new Datepicker(document.getElementById("toDate"), {
      clearBtn: true,
      autohide: true,
      minDate: new Date(),
      maxDate: new Date(),
      format: "d M yyyy",
    });

    document.getElementById("fromDate").addEventListener("changeDate", (ev) => {
      app.filter.from_date = Datepicker.formatDate(
        ev.detail.date,
        "yyyy-mm-dd"
      );

      toDate.setOptions({ minDate: ev.detail.date });

      if (toDate.getDate() < ev.detail.date) {
        toDate.setDate(ev.detail.date);
      }
    });

    document.getElementById("toDate").addEventListener("changeDate", (ev) => {
      this.filter.to_date = Datepicker.formatDate(ev.detail.date, "yyyy-mm-dd");
    });

    this.emitter.on("device_status_updated", (data) => {
      this.fetch_device();
    });

    this.emitter.on("device_checked_out", (data) => {
      this.fetch_device();
    });

    this.emitter.on("device_checked_in", (data) => {
      this.fetch_device();
    });

    this.fetch_device();
    this.fetch_activities();
    this.fetch_iotcore();

    setTimeout(() => {
      jQuery("[data-bs-toggle='tooltip']").each(function () {
        jQuery(this).tooltip();
      });
    }, 1000);
  },
  methods: {
    fetch_device() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");
      let router = this.$router;

      axios
        .get(process.env.MIX_APP_URL + "/api/v1/devices/" + this.device.eui, {
          headers: {
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          this.device = response.data;

          this.device.manufactured_date = moment()
            .year(2000 + this.device.manufactured_year)
            .startOf('year')
            .add(this.device.manufactured_week, "w")
            .format("YYYY-MM-DD HH:mm:SS");

          response.data.tags.forEach((item) => {
            this.tags.push({
              'text': item.name,
              'slug': item.slug
              });
          });

          this.emitter.emit("device_details_loaded", this.device);
        })
        .catch(function (error) {
          if (error.response && error.response.status == 404) {
            console.log('Error', error.message);
            router.push({name: 'NotFound' })
          }
        });
    },
    fetch_activities() {
      let url =
        process.env.MIX_RT_LOGS_ENDPOINT +
        "/activities?page=" +
        this.current_activity_page +
        "&filter[combined.key]=entity_id,properties.device.id&filter[combined.value]=" +
        this.device.eui;

      axios
        .get(url, {
          headers: {
            Authorization: process.env.MIX_RT_LOGS_SECRET,
          },
        })
        .then((response) => {
          this.activities = response.data.data;
          this.paginator = response.data.paginator;

          this.fetch_avatars();
        });
    },
    fetch_iotcore() {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .get(process.env.MIX_APP_URL + "/api/v1/devices/" + this.device.eui + "/iotcore", {
          headers: {
            Authorization:
              sessionTokens.token_type + " " + sessionTokens.access_token,
            "UMS-Authorization": umsTokens,
          },
        })
        .then((response) => {
          this.iotcore_device = response.data;
        });
    },
    fetch_avatars() {
      let userIds = {};

      for (let item in this.activities) {
        userIds[this.activities[item].causer_id] = 1;
      }

      axios
        .get(
          process.env.MIX_RT_UMS_URL +
            "users-avatars?users=" +
            Object.keys(userIds).join(","),
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            let users = response.data;

            for (let item in users) {
              jQuery("[data-user='" + users[item].id + "'] img").attr(
                "src",
                users[item].avatar
              );
            }
          } else {
            process.appdata.logout();
          }
        });
    },
    copy_eui() {
      var app = this;

      try {
        navigator.clipboard.writeText(this.device.eui).then(function () {
          app.emitter.emit("popup_alert", {
            type: "success",
            text: "Device ID was successfully copied to clipboard!",
          });
        });
      } catch (err) {
        app.emitter.emit("popup_alert", {
          type: "warning",
          text: "Your browser does not allow copying to clipboard or the application is loaded from non-secure context (HTTPS)",
        });
      }
    },
    save_notes() {
      if (null !== this.saveTimeout) {
        clearTimeout(this.saveTimeout);
      }

      let app = this;

      this.saveTimeout = setTimeout(() => {
        var sessionTokens = Storage.get("session_tokens", true),
          umsTokens = Storage.get("tokens");

        axios
          .patch(
            process.env.MIX_APP_URL + "/api/v1/devices/" + app.device.eui,
            {
              notes: app.device.notes,
            },
            {
              headers: {
                Authorization:
                  sessionTokens.token_type + " " + sessionTokens.access_token,
                "UMS-Authorization": umsTokens,
              },
            }
          )
          .then((response) => {
            app.notesSaved = true;

            setTimeout(() => {
              app.notesSaved = false;
            }, 1500);
          });
      }, 2000);
    },
    add_tag(obj) {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .patch(
          process.env.MIX_APP_URL +
            "/api/v1/devices/" +
            this.device.eui +
            "/tags",
          {
            tags: [obj.tag.text],
          },
          {
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
              "UMS-Authorization": umsTokens,
            },
          }
        )
        .then((response) => {
          this.tag='';
          this.tags = [];
          response.data.tags.forEach((item) => {
            this.tags.push({
              'text': item.name,
              'slug': item.slug
              });
          });
        });
    },
    delete_tag(obj) {
      var sessionTokens = Storage.get("session_tokens", true),
        umsTokens = Storage.get("tokens");

      axios
        .delete(
          process.env.MIX_APP_URL +
            "/api/v1/devices/" +
            this.device.eui +
            "/tags/" +
            obj.tag.slug,
          {
            headers: {
              Authorization:
                sessionTokens.token_type + " " + sessionTokens.access_token,
              "UMS-Authorization": umsTokens,
            },
          }
        )
        .then((response) => {
          this.tags = [];
          response.data.tags.forEach((item) => {
            this.tags.push({
              'text': item.name,
              'slug': item.slug
              });
          });
        });
    },
    changeStatus(nextStatus) {
      this.nextStatus = nextStatus
      $("#deviceStatus").modal('show');
    },
    getNextStatus(device_status) {
      switch (device_status) {
        case 'deployed':
          return 'deployed';
        case 'ready':
          return 'deployed';
        case 'undeployable':
          return 'ready';
        case 'broken':
          return 'ready';
        case 'lost':
          return 'ready';
        default:
          return 'N/A';
      }
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    capitalizeText(text) {
      return Utils.capitalizeText(text);
    },
  },
  watch: {
    "filter.log_type": function () {
      this.fetch_activities();
    },
    "filter.from_date": function () {
      this.fetch_activities();
    },
    "filter.to_date": function () {
      this.fetch_activities();
    },
    current_activity_page: function () {
      this.fetch_activities();
    },
  },
};
</script>